import React, { useMemo } from 'react';
import { useEvents } from '../../hooks/useEvents';
import EventSection from '../event/EventSection';
import EmptyEventSection from '../event/EmptyEventSection';

export default function EventsList() {
  const { events } = useEvents();

  const { pendingEvents, otherEvents } = useMemo(() => {
    const eventList = Array.isArray(events) ? events : [];
    const pending = eventList.filter((event) => event.approval_status === 'PENDING');
    const others = eventList
      .filter((event) => event.approval_status !== 'PENDING')
      .sort((a, b) => new Date(b.build_datetime).getTime() - new Date(a.build_datetime).getTime());

    return {
      pendingEvents: pending,
      otherEvents: others,
    };
  }, [events]);

  if (pendingEvents.length === 0 && otherEvents.length === 0) {
    return (
      <ul className="space-y-4">
        <EmptyEventSection title="Events" />
      </ul>
    );
  }

  return (
    <ul className="space-y-4">
      {pendingEvents.length > 0 && <EventSection events={pendingEvents} title="Waiting for approval events" />}
      <EventSection events={otherEvents} title="Active and decommissioned events" />
    </ul>
  );
}
