import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { DocumentTextIcon, EyeIcon, PencilIcon } from '@heroicons/react/24/outline';

interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  eventName: string;
  id?: string;
  label?: string;
  required?: boolean;
  help?: React.ReactNode;
  icon?: React.ElementType;
  testId?: string;
  hideEditButton?: boolean;
  hidePreviewButton?: boolean;
  initialPreviewMode?: boolean;
}

export default function MarkdownEditor({
  value,
  eventName,
  onChange,
  id = 'markdownEditor',
  label,
  required = false,
  help,
  icon = DocumentTextIcon,
  testId,
  hideEditButton = false,
  hidePreviewButton = false,
  initialPreviewMode = false,
}: MarkdownEditorProps) {
  const [isPreviewMode, setIsPreviewMode] = useState(initialPreviewMode);

  const getPreviewContent = (content: string): string => {
    return content
      .replace(/{{vars\.DATAOPS_SNOWFLAKE_ACCOUNT}}/g, 'SNOWFLAKE_ACCOUNT')
      .replace(/{{vars\.SNOWFLAKE_USER}}/g, 'SNOWFLAKE_USER')
      .replace(/{{vars\.SNOWFLAKE_PASSWORD}}/g, 'SNOWFLAKE_PASSWORD')
      .replace(/{{vars\.SNOWFLAKE_ROLE}}/g, 'SNOWFLAKE_ROLE')
      .replace(/{{vars\.SNOWFLAKE_DATABASE}}/g, 'SNOWFLAKE_DATABASE')
      .replace(/{{vars\.SNOWFLAKE_WAREHOUSE}}/g, 'SNOWFLAKE_WAREHOUSE')
      .replace(/{{vars\.DATAOPS_SNOWFLAKE_SCHEMA}}/g, 'DATAOPS_SNOWFLAKE_SCHEMA')
      .replace(/{{vars\.DATAOPS_SNOWFLAKE_COMPUTE_POOL}}/g, 'DATAOPS_SNOWFLAKE_COMPUTE_POOL')
      .replace(/{{vars\.DATAOPS_SNOWFLAKE_SPCS_REPO_NAME}}/g, 'DATAOPS_SNOWFLAKE_SPCS_REPO_NAME')
      .replace(/{{vars\.DATAOPS_SNOWFLAKE_SPCS_REPO_SCHEMA}}/g, 'DATAOPS_SNOWFLAKE_SPCS_REPO_SCHEMA')
      .replace(
        /{{vars\.DATAOPS_SNOWFLAKE_EXTERNAL_ACCESS_INTEGRATIONS}}/g,
        'DATAOPS_SNOWFLAKE_EXTERNAL_ACCESS_INTEGRATIONS',
      )
      .replace(/{{vars\.CONFIGURE_PIPELINE_ID}}/g, 'CONFIGURE_PIPELINE_ID')
      .replace(/{{vars\.CONFIGURE_PROJECT_HOMEPAGE_JOB_ID}}/g, 'CONFIGURE_PROJECT_HOMEPAGE_JOB_ID')
      .replace(/{{vars\.EVENT_NAME}}/g, eventName);
  };

  return (
    <div className="space-y-4">
      <div>
        <div className="flex justify-between">
          {label && (
            <label htmlFor={id} className="mb-2 block text-sm font-medium leading-6 text-gray-900">
              {label}
            </label>
          )}
          {required && <span className="mb-2 text-sm leading-6 text-gray-500 cursor-default">Required</span>}
        </div>
        <div className="space-y-2">
          {(!hideEditButton || !hidePreviewButton) && (
            <div className="flex justify-end space-x-2 mb-2">
              {!hideEditButton && (
                <button
                  type="button"
                  onClick={() => {
                    setIsPreviewMode(false);
                  }}
                  className={`px-3 py-1 text-sm rounded-md flex items-center ${
                    !isPreviewMode
                      ? 'bg-dataops-primary-light-blue text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <PencilIcon className="h-4 w-4 mr-1" />
                  Edit
                </button>
              )}
              {!hidePreviewButton && (
                <button
                  type="button"
                  onClick={() => {
                    setIsPreviewMode(true);
                  }}
                  className={`px-3 py-1 text-sm rounded-md flex items-center ${
                    isPreviewMode
                      ? 'bg-dataops-primary-light-blue text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <EyeIcon className="h-4 w-4 mr-1" />
                  Preview
                </button>
              )}
            </div>
          )}
          {isPreviewMode ? (
            <div className="border p-4 rounded-md bg-white min-h-[16rem] overflow-auto">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className="markdown"
                components={{
                  a: ({ node, children }) => (
                    <span className="text-blue-600 underline cursor-not-allowed">{children}</span>
                  ),
                }}
              >
                {getPreviewContent(value)}
              </ReactMarkdown>
            </div>
          ) : (
            <div className="relative">
              <textarea
                id={id}
                data-testid={testId}
                className="h-64 w-full border border-gray-200 bg-white px-4 py-2 pl-11 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-t-md outline-none outline-offset-0 focus:outline focus:outline-4 focus:outline-dataops-primary-light-blue/20 transition-all ease-in-out"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
              {icon &&
                React.createElement(icon, {
                  className: 'pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500',
                })}
            </div>
          )}
        </div>
        {help && (
          <div className="w-full px-4 py-2 leading-5 space-y-2 text-sm text-gray-700 bg-dataops-primary-dark-blue/5 rounded-b-md">
            {help}
          </div>
        )}
      </div>
    </div>
  );
}
