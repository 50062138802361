import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../../store';
import { fetchEvents, selectRequestStatus } from '../../reducers/eventsSlice';
import LoadingIndicator from '../common/LoadingIndicator';
import EventsList from '../project/EventsList';
import { useToken } from '../../hooks/useToken';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export default function EventDeploymentsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const token = useToken();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';

  const dispatchEventUpdates = () => {
    if (token) {
      dispatch(fetchEvents({ token, role })).catch((error) => {
        console.error('Failed to load event:', error);
      });
    }
  };

  useEffect(() => {
    if (token) {
      dispatchEventUpdates();
    }
  }, [dispatch, token]);

  return (
    <PageCenteredLayout>
      <h1 className="mb-12 text-3xl text-dataops-primary-dark-blue/40">Events</h1>
      <LoadingIndicator selectRequestStatus={selectRequestStatus} />
      <EventsList />
    </PageCenteredLayout>
  );
}
