import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch } from '../../hooks';
import { useToken } from '../../hooks/useToken';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import { CircleStackIcon, ChartBarSquareIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import { fetchEvent, fetchEventAttendees } from '../../reducers/eventsSlice';
import { useEvent } from '../../hooks/useEvent';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import Button from '../controls/Button';
import SectionLayout from '../layout/SectionLayout';
import EventAccountPoolAllocationBar from '../event/EventAccountPoolAllocationBar';
import PoolStatus from '../event/PoolStatus';
import NoMatchEvents from './NoMatchEvents';
import AccountsSection from '../event/AccountsSection';
import { type IChildAccount } from '../../interfaces/IChildAccount';
import ReconfigureAccountButton from '../controls/ReconfigureAccountButton';

export default function EventAccounts() {
  const token = useToken();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const { eventSlug } = useParams();
  const { event } = useEvent(eventSlug);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (eventSlug) {
      const fetchData = () => {
        dispatch(fetchEvent({ token, role, slug: eventSlug }))
          .then(() => {
            dispatch(fetchEventAttendees({ token, role, slug: eventSlug })).catch((err) => {
              console.log('Fetch eventAttendees error:', err);
            });
          })
          .catch((err) => {
            console.log('Fetch event error:', err);
          });
      };

      fetchData();

      const refreshInterval = setInterval(fetchData, 60000);

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [eventSlug, role, token, dispatch]);

  const getAllChildAccountIds = (): number[] => {
    return (event as any)?.child_accounts?.map((account: IChildAccount) => account.id) || [];
  };

  if (!event) {
    return <NoMatchEvents />;
  }

  const dateFormat = 'dddd D MMM YYYY';
  const formattedStartDate = dayjs(event.start_datetime).format(dateFormat);
  const totalReadyAccounts = event.account_pool?.total_ready_child_accounts ?? 0;
  const allocatedAccounts = event.account_pool?.total_allocated_child_accounts ?? 0;
  const allocatedPercentage = totalReadyAccounts === 0 ? 0 : (allocatedAccounts / totalReadyAccounts) * 100;

  return (
    <>
      <main>
        <header className="relative isolate pt-4 pb-2 bg-dataops-primary-light-blue/20">
          <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
          </div>

          <PageCenteredLayout>
            <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex flex-col items-start text-left gap-y-1 lg:mx-0 lg:max-w-none">
                <h1 className="text-xl font-bold text-dataops-primary-dark-blue">
                  <Link to={`/event-deployments/${event.slug}`} className="hover:underline">
                    {event.name}
                  </Link>{' '}
                  / Accounts
                </h1>
                <p className="text-base text-gray-600">{formattedStartDate}</p>
                <p className="text-sm text-gray-500">{event.location}</p>
              </div>
              {canPublish && (
                <div>
                  <ReconfigureAccountButton
                    accountIds={getAllChildAccountIds()}
                    eventSlug={eventSlug}
                    size="mediumPlus"
                    intent="primary"
                    label="Reconfigure all accounts"
                    className="flex items-center gap-2 hover:bg-dataops-primary-dark-blue/90 transition-colors"
                  />
                </div>
              )}
            </div>
            <div id="event-tabs" className="flex absolute bottom-0 h-10 overflow-hidden">
              <Button
                size="large"
                intent="custom"
                className="transition-all bg-dataops-primary-light-blue/20 hover:bg-dataops-primary-light-blue/30 text-dataops-primary-dark-blue rounded-none flex-col top-1 hover:top-0"
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <ListBulletIcon className="h-4 w-4" />
                  <span>Detail</span>
                </div>
              </Button>
              <Button
                size="large"
                intent="custom"
                className="transition-all bg-dataops-primary-light-blue/20 hover:bg-dataops-primary-light-blue/30 text-dataops-primary-dark-blue rounded-none flex-col top-1 hover:top-0"
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}/insights`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <ChartBarSquareIcon className="h-4 w-4" />
                  <span>Insights</span>
                </div>
              </Button>
              <Button
                size="large"
                intent="custom"
                className="bg-white text-dataops-primary-dark-blue rounded-none flex-col top-1"
                disabled
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}/accounts`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <CircleStackIcon className="h-4 w-4" />
                  <span>Accounts</span>
                </div>
              </Button>
            </div>
          </PageCenteredLayout>
        </header>

        <PageCenteredLayout extraClassName="py-8">
          <div className="w-full grid grid-cols-2 items-start gap-x-24 text-dataops-primary-dark-blue">
            <EventAccountPoolAllocationBar allocatedPercentage={allocatedPercentage} label="Account Pool" />
            <div className="flex flex-col gap-y-1 text-right text-sm">
              <p>Total Accounts: {totalReadyAccounts}</p>
              <p>Allocated Accounts: {allocatedAccounts}</p>
              <p>
                Pool Status: <PoolStatus status={event.account_pool?.status ?? 'No status available'} />
              </p>
            </div>
          </div>
        </PageCenteredLayout>

        <PageCenteredLayout extraClassName="py-8">
          <SectionLayout label="Account Details" className="bg-dataops-supporting-gray/40 md:col-span-4">
            <div className="grid grid-cols-4 gap-1 text-sm/6">
              <div className="col-span-1">Organization Account:</div>
              <div className="col-span-3">{event.organization_account}</div>
              <div className="col-span-1">Account Pool Size:</div>
              <div className="col-span-3">
                {event.account_pool?.pool_size ?? event.initial_pool_size ?? 'No size available'}
              </div>
              <div className="col-span-1">Account Region:</div>
              <div className="col-span-3">{event.snowflake_account_region_group}</div>
              <div className="col-span-1">Account Edition:</div>
              <div className="col-span-3">{event.snowflake_account_edition}</div>
            </div>
          </SectionLayout>
        </PageCenteredLayout>

        {(event as any).child_accounts && (event as any).child_accounts.length > 0 && (
          <PageCenteredLayout extraClassName="py-8">
            <SectionLayout label="Child Accounts" className="bg-dataops-supporting-gray/40 md:col-span-4">
              <ul className="space-y-4">
                {(() => {
                  const childAccounts = (event as any).child_accounts as IChildAccount[];
                  const allocatedAccounts = childAccounts.filter((account) => account.has_been_allocated);
                  const unallocatedAccounts = childAccounts.filter((account) => !account.has_been_allocated);

                  return (
                    <>
                      {unallocatedAccounts.length > 0 && (
                        <AccountsSection
                          accounts={unallocatedAccounts}
                          title="Unallocated Accounts"
                          canPublish={canPublish}
                          eventSlug={eventSlug}
                        />
                      )}
                      {allocatedAccounts.length > 0 && (
                        <AccountsSection
                          accounts={allocatedAccounts}
                          title="Allocated Accounts"
                          canPublish={canPublish}
                          eventSlug={eventSlug}
                        />
                      )}
                    </>
                  );
                })()}
              </ul>
            </SectionLayout>
          </PageCenteredLayout>
        )}
      </main>
    </>
  );
}
