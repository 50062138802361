import React from 'react';

export type PoolStatusType = 'pending' | 'ready' | 'resizing' | 'error' | 'decommissioning' | 'decommissioned';

interface PoolStatusProps {
  status: string;
  className?: string;
}

const PoolStatus: React.FC<PoolStatusProps> = ({ status, className = '' }) => {
  const normalizedStatus = status.toLowerCase() as PoolStatusType;

  const getStatusColor = (status: PoolStatusType): string => {
    switch (status) {
      case 'ready':
        return 'bg-green-100 text-green-800';
      case 'error':
        return 'bg-red-100 text-red-800';
      case 'decommissioned':
        return 'bg-gray-900 text-white';
      case 'decommissioning':
        return 'bg-gray-100 text-gray-800';
      case 'pending':
      case 'resizing':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: PoolStatusType): string => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getStatusColor(
        normalizedStatus,
      )} ${className}`}
    >
      {getStatusText(normalizedStatus)}
    </span>
  );
};

export default PoolStatus;
