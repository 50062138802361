import React, { useState } from 'react';
import EventAttendeeProgressRow from './EventAttendeeProgressRow';
import { type IEventAttendee, type IEventMilestone } from '../../reducers/eventsSlice';

interface Props {
  eventMilestones: IEventMilestone[];
  eventAttendees: IEventAttendee[] | null;
  nextMilestoneId: number | null;
}

type SortDirection = 'asc' | 'desc';

export default function EventAttendeeProgressTable({ eventMilestones, eventAttendees, nextMilestoneId }: Props) {
  const [sortField, setSortField] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  if (eventAttendees == null) {
    return <div>Loading...</div>;
  }

  const sortedData = [...eventAttendees].sort((a, b) => {
    const modifier = sortDirection === 'asc' ? 1 : -1;
    if (sortField === 'progress') {
      return (a.milestones.length - b.milestones.length) * modifier;
    }
    if (sortField === 'name' || sortField === 'account_identifier') {
      return a[sortField].localeCompare(b[sortField]) * modifier;
    }
    return 0;
  });

  return (
    <div className="space-y-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer"
              onClick={() => {
                handleSort('name');
              }}
            >
              Attendee {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th
              className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer"
              onClick={() => {
                handleSort('account');
              }}
            >
              Account {sortField === 'account_identifier' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th
              className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer"
              onClick={() => {
                handleSort('progress');
              }}
            >
              Progress {sortField === 'progress' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer">
              Last Completed Milestone
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedData.map((attendee) => (
            <EventAttendeeProgressRow
              key={attendee.email}
              attendee={attendee}
              eventMilestones={eventMilestones}
              nextMilestoneId={nextMilestoneId}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
