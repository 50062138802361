import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { type IEventMilestone, type IEventAttendee } from '../../reducers/eventsSlice';
import { localPoint } from '@visx/event';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 500,
};

interface Props {
  attendee: IEventAttendee;
  milestone: IEventMilestone;
  isNextMilestone: boolean;
}

export default function EventAttendeeProgressIcon({ attendee, milestone, isNextMilestone }: Props) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<string>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  return (
    <>
      <div
        className="flex items-center justify-center"
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipData: milestone.title,
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        <div
          className={`w-4 h-4 rounded-full ${
            attendee.milestones.some(
              (attendeeMilestone) =>
                attendeeMilestone.event_milestone_id === milestone.id && attendeeMilestone.achieved_by,
            )
              ? 'bg-green-500'
              : 'bg-gray-500'
          } ${isNextMilestone ? 'ring-2 ring-offset-2 ring-dataops-primary-light-blue' : ''}`}
        ></div>
      </div>

      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
