import React from 'react';

export type ChildAccountStatusType =
  | 'pending'
  | 'provisioning'
  | 'configuring'
  | 'ready'
  | 'pending-decommissioning'
  | 'decommissioning'
  | 'decommissioned'
  | 'error';

interface ChildAccountStatusProps {
  status: string;
  className?: string;
}

const ChildAccountStatus: React.FC<ChildAccountStatusProps> = ({ status, className = '' }) => {
  const normalizedStatus = status.toLowerCase() as ChildAccountStatusType;

  const getStatusColor = (status: ChildAccountStatusType): string => {
    switch (status) {
      case 'ready':
        return 'bg-green-100 text-green-800';
      case 'error':
        return 'bg-red-100 text-red-800';
      case 'decommissioned':
        return 'bg-gray-900 text-white';
      case 'decommissioning':
      case 'pending-decommissioning':
        return 'bg-gray-100 text-gray-800';
      case 'pending':
      case 'provisioning':
      case 'configuring':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status: ChildAccountStatusType): string => {
    if (status === 'pending-decommissioning') {
      return 'Pending Decommissioning';
    }

    return status
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getStatusColor(
        normalizedStatus,
      )} ${className}`}
    >
      {getStatusText(normalizedStatus)}
    </span>
  );
};

export default ChildAccountStatus;
