import React, { useEffect } from 'react';
import './App.css';
import SidebarLayout from './components/layout/SidebarLayout';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import OAuthCallback from './components/pages/OAuthCallback';
import { fetchUser } from './reducers/accountSlice';
import { useAppDispatch } from './hooks';
import Search from './components/pages/Search';
import LastVisitedUrlTracker from './components/nav/LastVisitedUrlTracker';
import DeploymentsPage from './components/pages/DeploymentsPage';
import NewSolutionPage from './components/pages/NewSolutionPage';
import NewFromTemplate from './components/pages/NewFromTemplate';
import NewFromNotebook from './components/pages/NewFromNotebookPage';
import NewFromGitPath from './components/pages/NewFromGitPage';
import Imported from './components/pages/Imported';
import Import from './components/pages/Import';
import SolutionPage from './components/pages/SolutionPage';
import SupportPage from './components/pages/SupportPage';
import DeploymentPage from './components/pages/DeploymentPage';
import ManageSolutionDraftsPage from './components/pages/ManageSolutionDraftsPage';
import { useToken } from './hooks/useToken';
import NoMatch from './components/pages/NoMatch';
import HeroLayout from './components/layout/HeroLayout';
import EventDeploymentsPage from './components/pages/EventDeploymentsPage';
import EventDeploymentPage from './components/pages/EventDeploymentPage';
import { useCurrentUser } from './hooks/useCurrentUser';
import { parseGuid } from './utilities/parsers';
import { getConfig } from './config/config-helper';
import NewEventSolution from './components/pages/NewEventSolution';
import EventInsights from './components/pages/EventInsights';
import EventAccounts from './components/pages/EventAccounts';

declare global {
  interface Window {
    Appcues: any;
  }
}

export default function App() {
  const token = useToken();
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const { dataopsShareEnvironment } = getConfig();

  useEffect(() => {
    if (token !== '') {
      dispatch(fetchUser(token)).catch((err) => {
        console.log('Fetch username error:', err);
      });
    }
    if (user.id !== '') {
      window.Appcues.identify(parseGuid(user.id), {
        email: user.emails.nodes[0]?.email,
        name: user.username,
        role: user.canPublish ? 'publisher' : user.canConsume ? 'consumer' : 'read-only',
        stage: dataopsShareEnvironment,
      });
    }
  }, [token, user.id]);

  useEffect(() => {
    const storageVersionKey = 'storageVersion';
    const storageVersion = '1';
    // Check for local storage version, if not present, clear all local storage and add version.
    // This is to ensure that local storage is cleared when the version changes.
    if (!localStorage.getItem(storageVersionKey) || localStorage.getItem(storageVersionKey) !== storageVersion) {
      localStorage.clear();
      localStorage.setItem(storageVersionKey, storageVersion);
    }
  }, []);

  return (
    <div>
      <Router>
        <LastVisitedUrlTracker />
        <Routes>
          <Route element={<HeroLayout />}>
            <Route path="/s" element={<Search quickstartResults />} />
          </Route>
          <Route element={<SidebarLayout />}>
            <Route path="*" element={<NoMatch />} />
            <Route path="/" element={<Navigate to="/solutions" replace />} />
            <Route path="/solutions" element={<Search />} />
            <Route path="/solutions/:id" element={<SolutionPage />} />
            <Route path="/new/event" element={<NewEventSolution />} />
            <Route path="/deployments" element={<DeploymentsPage />} />
            <Route path="/deployments/:id" element={<DeploymentPage />} />
            <Route path="/event-deployments" element={<EventDeploymentsPage />} />
            <Route path="/event-deployments/:eventSlug" element={<EventDeploymentPage />} />
            <Route path="/event-deployments/:eventSlug/insights" element={<EventInsights />} />
            <Route path="/event-deployments/:eventSlug/accounts" element={<EventAccounts />} />
            <Route path="/new" element={<NewSolutionPage />} />
            <Route path="/new/from-framework" element={<NewFromTemplate />} />
            <Route path="/new/from-notebook" element={<NewFromNotebook />} />
            <Route path="/new/from-git" element={<NewFromGitPath />} />
            <Route path="/new/from-git/:id" element={<Imported />} />
            <Route path="/new/from-git/import/:id" element={<Import />} />
            <Route path="/manage/solution-drafts" element={<ManageSolutionDraftsPage />} />
            <Route path="/support" element={<SupportPage />} />
          </Route>
          <Route path="/dataops-oauth-callback" element={<OAuthCallback />} />
        </Routes>
      </Router>
    </div>
  );
}
