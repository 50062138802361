import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Button from '../controls/Button';
import { useNavigate } from 'react-router-dom';

export default function NoMatch() {
  const navigate = useNavigate();
  return (
    <div className="z-50 flex flex-col items-center justify-center h-screen backdrop-blur-lg space-y-6">
      <QuestionMarkCircleIcon className="h-12 w-12 text-gray-400 mx-auto" />
      <h3 className="text-lg text-gray-900">Not a URL we recognize</h3>
      <p className="text-sm text-gray-500">This event does not exist, or you are not an instructor</p>
      <div className="flex justify-center">
        <Button
          intent={'light'}
          onClick={() => {
            navigate('/event-deployments');
          }}
        >
          Events
        </Button>
      </div>
    </div>
  );
}
