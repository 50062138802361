import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAppDispatch } from '../../hooks';
import { useToken } from '../../hooks/useToken';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import { ClockIcon, ChartBarSquareIcon, ListBulletIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import EventProgressChart from '../event/EventProgressChart';
import EventAttendeeProgressTable from '../event/EventAttendeeProgressTable';
import { fetchEvent, fetchEventAttendees, type IEventMilestone } from '../../reducers/eventsSlice';
import { useEvent } from '../../hooks/useEvent';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import Button from '../controls/Button';
import SectionLayout from '../layout/SectionLayout';
import EventAccountPoolAllocationBar from '../event/EventAccountPoolAllocationBar';
import NoMatchEvents from './NoMatchEvents';
import { dateFormat, dateTimeFormat } from '../../utilities/constants';

const calculateTimeRemaining = (endDateTime: string): string => {
  const now = dayjs();
  const end = dayjs(endDateTime);

  if (now.isAfter(end)) {
    return 'Event ended';
  }

  const diff = end.diff(now, 'second');
  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = diff % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const findNextExpectedMilestone = (milestones: IEventMilestone[]) => {
  const now = dayjs();
  const nextMilestone = milestones
    .filter((m) => dayjs(m.expected_by_timestamp).isAfter(now))
    .sort((a, b) => dayjs(a.expected_by_timestamp).diff(dayjs(b.expected_by_timestamp)))[0];

  if (!nextMilestone) return null;

  const minutesRemaining = dayjs(nextMilestone.expected_by_timestamp).diff(now, 'minute');
  return { milestone: nextMilestone, minutesRemaining };
};

export default function EventInsights() {
  const token = useToken();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const { eventSlug } = useParams();
  const { event } = useEvent(eventSlug);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [sortedMilestones, setSortedMilestones] = useState<IEventMilestone[]>([]);
  const [nextMilestone, setNextMilestone] = useState<{ milestone: IEventMilestone; minutesRemaining: number } | null>(
    null,
  );

  // Add data refresh effect
  useEffect(() => {
    if (eventSlug) {
      const fetchData = () => {
        dispatch(fetchEvent({ token, role, slug: eventSlug }))
          .then(() => {
            dispatch(fetchEventAttendees({ token, role, slug: eventSlug })).catch((err) => {
              console.log('Fetch eventAttendees error:', err);
            });
          })
          .catch((err) => {
            console.log('Fetch event error:', err);
          });
      };

      // Initial fetch
      fetchData();

      // Set up periodic refresh
      const refreshInterval = setInterval(fetchData, 60000); // Refresh every minute

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [eventSlug, role, token]);

  useEffect(() => {
    if (event?.end_datetime) {
      setTimeRemaining(calculateTimeRemaining(event.end_datetime));

      const timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining(event.end_datetime));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [event?.end_datetime]);

  useEffect(() => {
    if (event?.milestones) {
      setSortedMilestones(event.milestones.slice().sort((a, b) => a.sequence_order - b.sequence_order));
    }
  }, [event?.milestones]);

  useEffect(() => {
    if (sortedMilestones.length > 0) {
      const updateNextMilestone = () => {
        setNextMilestone(findNextExpectedMilestone(sortedMilestones));
      };

      updateNextMilestone();
      const timer = setInterval(updateNextMilestone, 60000); // Update every minute

      return () => {
        clearInterval(timer);
      };
    }
  }, [sortedMilestones]);

  if (!event) {
    return <NoMatchEvents />;
  }

  const formattedStartDate = dayjs(event.start_datetime).format(dateFormat);
  const totalReadyAccounts = event.account_pool?.total_ready_child_accounts ?? 0;
  const allocatedAccounts = event.account_pool?.total_allocated_child_accounts ?? 0;
  const allocatedPercentage = totalReadyAccounts === 0 ? 0 : (allocatedAccounts / totalReadyAccounts) * 100;

  return (
    <>
      <main>
        <header className="relative isolate pt-4 pb-2 bg-dataops-primary-light-blue/20">
          <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
          </div>

          <PageCenteredLayout>
            <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="mx-auto flex flex-col items-start text-left gap-y-1 lg:mx-0 lg:max-w-none">
                <h1 className="text-xl font-bold text-dataops-primary-dark-blue">
                  <Link to={`/event-deployments/${event.slug}`} className="hover:underline">
                    {event.name}
                  </Link>{' '}
                  / Insights
                </h1>
                <p className="text-base text-gray-600">{formattedStartDate}</p>
                <p className="text-sm text-gray-500">{event.location}</p>
              </div>

              <div className="flex flex-col items-end gap-y-2 sm:gap-x-1">
                {/* Event time remaining */}
                <div className="flex items-center px-2 bg-black justify-left text-nowrap text-yellow-300 font-bold font-mono">
                  <ClockIcon className="mr-1 h-[1em]" />
                  <span>{timeRemaining}</span>
                </div>
                {/* Next expected milestone to be completed and when */}
                <div className="text-gray-500">
                  {nextMilestone
                    ? `"${nextMilestone.milestone.title}" due in ${nextMilestone.minutesRemaining} minutes`
                    : 'No upcoming milestones'}
                </div>
              </div>
            </div>
            <div id="event-tabs" className="flex absolute bottom-0 h-10 overflow-hidden">
              <Button
                size="large"
                intent="custom"
                className="transition-all bg-dataops-primary-light-blue/20 hover:bg-dataops-primary-light-blue/30 text-dataops-primary-dark-blue rounded-none flex-col top-1 hover:top-0"
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <ListBulletIcon className="h-4 w-4" />
                  <span>Detail</span>
                </div>
              </Button>
              <Button
                size="large"
                intent="custom"
                className="bg-white text-dataops-primary-dark-blue rounded-none flex-col top-1"
                disabled
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}/insights`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <ChartBarSquareIcon className="h-4 w-4" />
                  <span>Insights</span>
                </div>
              </Button>
              <Button
                size="large"
                intent="custom"
                className="transition-all bg-dataops-primary-light-blue/20 hover:bg-dataops-primary-light-blue/30 text-dataops-primary-dark-blue rounded-none flex-col top-1 hover:top-0"
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}/accounts`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <CircleStackIcon className="h-4 w-4" />
                  <span>Accounts</span>
                </div>
              </Button>
            </div>
          </PageCenteredLayout>
        </header>

        <PageCenteredLayout extraClassName="py-8">
          <div className="w-full grid grid-cols-2 items-start gap-x-24 text-dataops-primary-dark-blue">
            <EventAccountPoolAllocationBar allocatedPercentage={allocatedPercentage} label="Account Pool" />
            <div className="flex flex-col gap-y-1 text-right text-sm">
              <p>Start: {dayjs(event.start_datetime).format(dateTimeFormat)}</p>
              <p>End: {dayjs(event.end_datetime).format(dateTimeFormat)}</p>
            </div>
          </div>
        </PageCenteredLayout>

        {event.eventAttendees ? (
          <>
            <PageCenteredLayout extraClassName="py-8">
              <EventProgressChart eventMilestones={sortedMilestones} eventAttendees={event.eventAttendees} />
            </PageCenteredLayout>

            <PageCenteredLayout extraClassName="py-8">
              <EventAttendeeProgressTable
                eventMilestones={sortedMilestones}
                eventAttendees={event.eventAttendees}
                nextMilestoneId={nextMilestone?.milestone.id ?? null}
              />
            </PageCenteredLayout>
          </>
        ) : (
          <InsightsPageSkeleton />
        )}
      </main>
    </>
  );
}

function InsightsPageSkeleton() {
  return (
    <>
      <PageCenteredLayout extraClassName="py-8">
        {/* <div className="h-64 bg-gray-400" /> */}
        <SectionLayout
          skeleton
          label="EventProgressChart"
          className="bg-dataops-supporting-gray/40 md:col-span-1 md:col-start-3 h-64"
        >
          <div className="flex space-x-2">
            <p className="w-full h-36 rounded-lg bg-shine flex-col"></p>
            <p className="w-full h-36 rounded-lg bg-shine flex-col"></p>
            <p className="w-full h-36 rounded-lg bg-shine flex-col"></p>
          </div>
        </SectionLayout>
      </PageCenteredLayout>
      <PageCenteredLayout extraClassName="py-8">
        <SectionLayout
          skeleton
          label="EventAttendeeProgressTable"
          className="bg-dataops-supporting-gray/40 md:col-span-1 md:col-start-3 h-64"
        >
          <div className="space-y-2">
            <p className="w-full h-12 rounded-lg bg-shine"></p>
            <p className="w-full h-12 rounded-lg bg-shine"></p>
            <p className="w-full h-12 rounded-lg bg-shine"></p>
          </div>
        </SectionLayout>
      </PageCenteredLayout>
      ;
    </>
  );
}
