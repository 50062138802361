import * as React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { type IBroadcast } from '../../interfaces/IBroadcast';
import { selectAcknowledgedBroadcastIds, setBroadcastAcknowledged } from '../../reducers/accountSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Markdown from 'react-markdown';
import { MegaphoneIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import rehypeRaw from 'rehype-raw';
import Button from '../controls/Button';
import NewVerticalNav202410 from '../../assets/images/promo/202410_new_vertical_nav.gif';
import { type AppDispatch } from '../../store';

interface IBroadcastBannersProps {
  broadcastData: IBroadcast[];
}

function BroadcastBannerNewLayout(acknowledgedBroadcasts: number[], broadcast: IBroadcast, dispatch: AppDispatch) {
  return (
    <div
      key={broadcast.id}
      className={`relative isolate flex items-start gap-x-6 overflow-hidden text-dataops-primary-dark-blue  px-6 py-2.5 sm:px-3.5 rounded-none md:rounded-md sm:before:flex-1 shadow-lg`}
    >
      <div className="flex flex-row items-start gap-x-4 gap-y-2">
        <div className="pt-1">
          <MegaphoneIcon className="w-5 min-w-5" />
        </div>
        <div className="flex flex-col px-4 sm:px-0 gap-y-1">
          {broadcast.title !== undefined && <h3 className="text-base font-semibold leading-7">{broadcast.title}</h3>}
          <div className="max-w-2xl text-sm leading-6 text-dataops-primary-dark-blue">
            <Markdown linkTarget="_blank" rehypePlugins={[rehypeRaw]}>
              {broadcast.message}
            </Markdown>
          </div>
          {/* CUSTOM IMAGE */}
          <div className="w-60">
            <img src={NewVerticalNav202410} alt="" />
          </div>
          {/* CUSTOM BUTTONS */}
          <div className="flex flex-row">
            <div className="flex-shrink-0">
              <Button
                onClick={() => {
                  window.Appcues.show('8c7d595b-cb44-4927-b973-a5318db641a2');
                }}
              >
                <PlayCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" /> Take a tour
              </Button>
            </div>
            <div className="flex-shrink-0">
              <Button
                intent={'subtle'}
                onClick={() => {
                  dispatch(setBroadcastAcknowledged([broadcast.id, ...acknowledgedBroadcasts]));
                }}
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 justify-end h-full">
        {broadcast.dismissable && (
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            onClick={() => {
              dispatch(setBroadcastAcknowledged([broadcast.id, ...acknowledgedBroadcasts]));
            }}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-10" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
}

function BroadcastBannerDefault(acknowledgedBroadcasts: number[], broadcast: IBroadcast, dispatch: AppDispatch) {
  return (
    <div
      key={broadcast.id}
      className={`relative isolate flex items-start gap-x-6 overflow-hidden text-dataops-primary-dark-blue  px-6 py-2.5 sm:px-3.5 rounded-none md:rounded-md sm:before:flex-1 shadow-lg`}
    >
      <div className="flex flex-row items-start gap-x-4 gap-y-2">
        <div className="pt-1">
          <MegaphoneIcon className="w-5 min-w-5" />
        </div>
        <div className="flex flex-col px-4 sm:px-0 gap-y-1">
          {broadcast.title !== undefined && <h3 className="text-base font-semibold leading-7">{broadcast.title}</h3>}
          <div className="max-w-2xl text-sm leading-6 text-dataops-primary-dark-blue">
            <Markdown linkTarget="_blank" rehypePlugins={[rehypeRaw]}>
              {broadcast.message}
            </Markdown>
          </div>
        </div>
      </div>
      <div className="flex flex-1 justify-end h-full">
        {broadcast.dismissable && (
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            onClick={() => {
              dispatch(setBroadcastAcknowledged([broadcast.id, ...acknowledgedBroadcasts]));
            }}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-10" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
}

function getBroadcastComponent(acknowledgedBroadcasts: number[], broadcast: IBroadcast, dispatch: AppDispatch) {
  switch (broadcast.render_component) {
    case 'BroadcastsBannerNewLayout':
      return BroadcastBannerNewLayout(acknowledgedBroadcasts, broadcast, dispatch);
    default:
      return BroadcastBannerDefault(acknowledgedBroadcasts, broadcast, dispatch);
  }
}

export default function BroadcastBanners(props: IBroadcastBannersProps) {
  // Filter out target_path that starts with `snowflake`.
  const dispatch = useAppDispatch();
  const acknowledgedBroadcasts = useAppSelector(selectAcknowledgedBroadcastIds);
  const banners = props.broadcastData
    .filter((broadcast) => broadcast.broadcast_type === 'banner')
    .filter((broadcast) => broadcast.active)
    .filter((broadcast) => broadcast.target_path?.startsWith('/snowflake'))
    .filter((broadcast) => !acknowledgedBroadcasts.includes(broadcast.id))
    .filter((broadcast) => new Date(broadcast.starts_at) <= new Date())
    .filter((broadcast) => new Date(broadcast.ends_at) >= new Date());

  return (
    <div className="broadcast banner px-0 md:px-2">
      {banners.map((banner) => getBroadcastComponent(acknowledgedBroadcasts, banner, dispatch))}
    </div>
  );
}
