import React from 'react';

const EmptyEventSection = ({ title }: { title: string }) => (
  <li className="space-y-2 rounded-md px-2 py-4 bg-dataops-supporting-gray/40 list-none">
    <div className="flex items-center justify-between">
      <h2 className="flex-grow px-2 text-md text-dataops-secondary-dark-blue">{title}</h2>
      <div className="flex justify-center items-center w-6 h-6 mr-2 rounded-full bg-dataops-primary-dark-blue/10 text-xs">
        0
      </div>
    </div>
    <div className="relative grid grid-cols-11 gap-x-4 px-2 py-2">
      <div className="col-span-3 flex items-center justify-left text-sm">Event</div>
      <div className="col-span-2 flex items-center justify-left text-sm">Approval Status</div>
      <div className="col-span-2 flex items-center justify-center text-sm">Build Date</div>
      <div className="col-span-2 flex items-center justify-center text-sm">Decommission Date </div>
      <div className="col-span-2 flex items-center justify-left text-sm">Accounts allocated</div>
    </div>
    <div className="text-center text-gray-500">No events</div>
  </li>
);

export default EmptyEventSection;
