export const defaultInstructions = `# {{vars.EVENT_NAME}}

---

## Welcome.

Open step by step instructions:

<a href="https://app.dataops.live/{{vars.CONFIGURE_PROJECT_PATH_WITH_NAMESPACE}}/documentations/{{vars.CONFIGURE_PIPELINE_ID}}?job={{vars.CONFIGURE_PROJECT_HOMEPAGE_JOB_ID}})" target="_blank" rel="noopener noreferrer">
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="50">
    <rect x="0" y="0" width="200" height="50" rx="10" ry="10" fill="#007bff" />
    <text x="100" y="30" font-family="Arial, sans-serif" font-size="16" fill="white" text-anchor="middle">
        Lab Instructions
    </text>
  </svg>
</a>

<br>

## Things you will need later

### Your temporary Snowflake account

<div class="mt-6 border-t border-gray-100">
  <dl class="divide-y space-y-2 divide-gray-100">
    <div class="px-4  grid grid-cols-4">
      <dt class="text-md/6 font-medium text-gray-900">URL</dt>
      <dd class="text-md/6 text-gray-700 col-span-3"><a href="https://{{vars.DATAOPS_SNOWFLAKE_ACCOUNT}}.snowflakecomputing.com" target="_blank" rel="noopener noreferrer">https://{{vars.DATAOPS_SNOWFLAKE_ACCOUNT}}.snowflakecomputing.com</a></dd>
    </div>
    <div class="px-4  grid grid-cols-4">
      <dt class="text-md/6 font-medium text-gray-900">Username</dt>
      <dd class="text-md/6 text-gray-700 col-span-3">{{vars.SNOWFLAKE_USER}}</dd>
    </div>
    <div class="px-4  grid grid-cols-4">
      <dt class="text-md/6 font-medium text-gray-900">Password</dt>
      <dd class="text-md/6 text-gray-700 col-span-3">{{vars.SNOWFLAKE_PASSWORD}}</dd>
    </div>
  </dl>
</div>

#### Environment

<div class="mt-6 border-t border-gray-100">
  <dl class="divide-y space-y-2 divide-gray-100">
    <div class="px-4  grid grid-cols-4">
      <dt class="text-md/6 font-medium text-gray-900">Role</dt>
      <dd class="text-md/6 text-gray-700 col-span-3">{{vars.SNOWFLAKE_ROLE}}</dd>
    </div>
    <div class="px-4  grid grid-cols-4">
      <dt class="text-md/6 font-medium text-gray-900">Database</dt>
      <dd class="text-md/6 text-gray-700 col-span-3">{{vars.SNOWFLAKE_DATABASE}}</dd>
    </div>
  </dl>
</div>
<br>

---

## Debug Info

<details>
  <summary>Click here for detailed debugging information</summary>

  <br>

<a href="https://app.dataops.live/{{vars.CONFIGURE_PROJECT_PATH_WITH_NAMESPACE}}/-/pipelines/{{vars.CONFIGURE_PIPELINE_ID}}" target="_blank" rel="noopener noreferrer">Account Configuration Pipeline</a>

  <br>

### Variables

  <div class="text-xs bg-dataops-primary-dark-blue/5">

\`\`\`bash
DATAOPS_SNOWFLAKE_ACCOUNT: {{vars.DATAOPS_SNOWFLAKE_ACCOUNT}}
SNOWFLAKE_ROLE: {{vars.SNOWFLAKE_ROLE}}
SNOWFLAKE_USER: {{vars.SNOWFLAKE_USER}}
SNOWFLAKE_DATABASE: {{vars.SNOWFLAKE_DATABASE}}
SNOWFLAKE_PASSWORD: {{vars.SNOWFLAKE_PASSWORD}}
SNOWFLAKE_WAREHOUSE: {{vars.SNOWFLAKE_WAREHOUSE}}
DATAOPS_SNOWFLAKE_SCHEMA: {{vars.DATAOPS_SNOWFLAKE_SCHEMA}}
DATAOPS_SNOWFLAKE_COMPUTE_POOL: {{vars.DATAOPS_SNOWFLAKE_COMPUTE_POOL}}
DATAOPS_SNOWFLAKE_SPCS_REPO_NAME: {{vars.DATAOPS_SNOWFLAKE_SPCS_REPO_NAME}}
DATAOPS_SNOWFLAKE_SPCS_REPO_SCHEMA: {{vars.DATAOPS_SNOWFLAKE_SPCS_REPO_SCHEMA}}
DATAOPS_SNOWFLAKE_EXTERNAL_ACCESS_INTEGRATIONS: {{vars.DATAOPS_SNOWFLAKE_EXTERNAL_ACCESS_INTEGRATIONS}}
\`\`\`

  </div>
</details>
`;
