/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import ImportColumnLayout from '../layout/ImportColumnLayout';
import { classNames } from '../../utilities/styleUtils';
import { MagnifyingGlassIcon, BoltIcon, LockClosedIcon, TicketIcon, BeakerIcon } from '@heroicons/react/24/outline';
import Button from '../controls/Button';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchEventProjects, selectEventProjects, selectEventProjectsStatus } from '../../reducers/projectsSlice';
import { useToken } from '../../hooks/useToken';
import { Input } from '../controls/Input';
import MarkdownEditor from '../controls/MarkdownEditor';
import { defaultInstructions } from './defaultInstructions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import {
  CalendarDaysIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  FingerPrintIcon,
  HashtagIcon,
  MapPinIcon,
  GlobeAltIcon,
} from '@heroicons/react/20/solid';
import { type DateValueType } from 'react-tailwindcss-datepicker';
import InputDatepicker from '../controls/InputDatepicker';
import axios from 'axios';
import { getConfig } from '../../config/config-helper';
import { InputSelect, type SelectOption } from '../controls/InputSelect';
import { salesForceIdValidation, ValidationLevel } from '../../utilities/validation';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

interface EventCreateRequest {
  name: string;
  location: string;
  build_date: string;
  start_date: string;
  end_date: string;
  decommission_date: string;
  grant_reporter_access_to_configure_project: boolean;
  organization_account_identifier: string;
  extra_env_vars: Record<string, string>;
  instructions: string;
  initial_pool_size: number;
  dataops_configure_project_path: string;
  dataops_configure_project_pipe_file: string;
  dataops_configure_project_git_ref: string;
  dataops_top_level_group_path: string;
  snowflake_account_region_group: string;
  snowflake_account_edition: string;
}
export default function NewEventSolution() {
  const { dataopsAdminAppApiEndpoint, snowflakeOrganizationAccounts } = getConfig();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useToken();
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 365);

  const dateSeed = {
    startDate: null,
    endDate: null,
  };
  const [buildDateValue, setBuildDateValue] = useState<DateValueType>(dateSeed);
  const [eventDateValue, setEventDateValue] = useState<DateValueType>(dateSeed);
  const [deleteDateValue, setDeleteDateValue] = useState<DateValueType>(dateSeed);

  const [eventName, setEventName] = useState('');
  const [eventSlug, setEventSlug] = useState('');
  const [slugError, setSlugError] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [eventLocation, setEventLocation] = useState('Virtual');
  const deliveryFormatOptions: SelectOption[] = [
    {
      id: 'WORKSHOP',
      name: 'Workshop',
      description:
        'A structured group session focused on interactive learning, where attendees engage in discussions, activities, and collaborative projects, often facilitated by an instructor.',
    },
    {
      id: 'HANDS_ON_LAB',
      name: 'Hands On Lab',
      description:
        'A practical session that emphasizes experiential learning, allowing attendees to apply theoretical knowledge through direct manipulation of tools, technologies, or processes in a controlled environment.',
    },
    {
      id: 'TRAINING',
      name: 'Training',
      description:
        "A formal program aimed at enhancing attendees' skills and knowledge in a specific area, which may include lectures, demonstrations, and hands-on practice, often with a focus on professional development.",
    },
    {
      id: 'HACKATHON',
      name: 'Hackathon',
      description:
        'A collaborative event where participants work together to solve problems, build solutions, or create new applications within a limited timeframe, often with a competitive element.',
    },
    {
      id: 'OTHER',
      name: 'Other',
      description:
        'Any session type that does not fit into the predefined categories, allowing to specify alternative formats or experiences for attendees.',
    },
  ];
  const [deliveryFormat, setDeliveryFormat] = useState<SelectOption>({ id: 'HANDS_ON_LAB', name: 'Hands On Lab' });
  const [totalAttendees, setTotalAttendees] = useState('');
  const [instructorAccounts, setInstructorAccounts] = useState('');
  const [salesForceId, setSalesForceId] = useState('');
  const [salesForceIdError, setSalesForceIdError] = useState('');
  const updateInstructions = useCallback((name: string) => {
    let updatedInstructions = defaultInstructions;

    updatedInstructions = updatedInstructions.replace('EVENT_NAME_PLACEHOLDER', name ?? '{{ vars.EVENT_NAME }}');

    return updatedInstructions;
  }, []);

  const [instructions, setInstructions] = useState(updateInstructions(''));

  // Get solutions from Redux store
  const eventProjects = useAppSelector(selectEventProjects);
  const eventProjectsStatus = useAppSelector(selectEventProjectsStatus);
  const [selectedSolution, setSelectedSolution] = useState<SelectOption | null>(null);

  useEffect(() => {
    if (eventName) {
      setInstructions(updateInstructions(eventName));
    }
  }, [eventName, selectedSolution, updateInstructions]);
  const [solutionsError, setSolutionsError] = useState<string | undefined>(undefined);

  const getTimezoneOffset = (tzId: string): string => {
    return dayjs().tz(tzId).format('Z');
  };

  const timezoneOptions: SelectOption[] = [
    { id: 'UTC', name: 'UTC (Coordinated Universal Time)' },
    { id: 'America/New_York', name: 'Eastern Time (ET)' },
    { id: 'America/Chicago', name: 'Central Time (CT)' },
    { id: 'America/Denver', name: 'Mountain Time (MT)' },
    { id: 'America/Los_Angeles', name: 'Pacific Time (PT)' },
    { id: 'Europe/London', name: 'London (GMT/BST)' },
    { id: 'Europe/Paris', name: 'Central European Time (CET)' },
    { id: 'Asia/Singapore', name: 'Singapore Time (SGT)' },
    { id: 'Australia/Sydney', name: 'Australian Eastern Time (AET)' },
    { id: 'Asia/Tokyo', name: 'Japan Standard Time (JST)' },
    { id: 'Asia/Shanghai', name: 'China Standard Time (CST)' },
    { id: 'Asia/Kolkata', name: 'India Standard Time (IST)' },
    { id: 'Europe/Berlin', name: 'Central European Time (CET)' },
    { id: 'Europe/Moscow', name: 'Moscow Standard Time (MSK)' },
    { id: 'America/Sao_Paulo', name: 'Brasilia Time (BRT)' },
    { id: 'Africa/Johannesburg', name: 'South Africa Standard Time (SAST)' },
    { id: 'Pacific/Auckland', name: 'New Zealand Standard Time (NZST)' },
  ];
  const [timezone, setTimezone] = useState<SelectOption>(timezoneOptions[0]);

  // Account region options based on Snowflake documentation
  // https://docs.snowflake.com/en/user-guide/admin-account-identifier#region-ids
  const regionOptions: SelectOption[] = [
    // AWS regions
    { id: 'aws_us_west_2', name: 'US West (Oregon) (aws_us_west_2)' },
    { id: 'aws_us_east_1', name: 'US East (N. Virginia) (aws_us_east_1)' },
    { id: 'aws_us_east_2', name: 'US East (Ohio) (aws_us_east_2)' },
    { id: 'aws_ca_central_1', name: 'Canada (Central) (aws_ca_central_1)' },
    { id: 'aws_sa_east_1', name: 'South America (São Paulo) (aws_sa_east_1)' },
    { id: 'aws_eu_west_1', name: 'EU (Ireland) (aws_eu_west_1)' },
    { id: 'aws_eu_west_2', name: 'EU (London) (aws_eu_west_2)' },
    { id: 'aws_eu_west_3', name: 'EU (Paris) (aws_eu_west_3)' },
    { id: 'aws_eu_central_1', name: 'EU (Frankfurt) (aws_eu_central_1)' },
    { id: 'aws_eu_central_2', name: 'EU (Zurich) (aws_eu_central_2)' },
    { id: 'aws_eu_north_1', name: 'EU (Stockholm) (aws_eu_north_1)' },
    { id: 'aws_ap_northeast_1', name: 'Asia Pacific (Tokyo) (aws_ap_northeast_1)' },
    { id: 'aws_ap_northeast_3', name: 'Asia Pacific (Osaka) (aws_ap_northeast_3)' },
    { id: 'aws_ap_northeast_2', name: 'Asia Pacific (Seoul) (aws_ap_northeast_2)' },
    { id: 'aws_ap_south_1', name: 'Asia Pacific (Mumbai) (aws_ap_south_1)' },
    { id: 'aws_ap_southeast_1', name: 'Asia Pacific (Singapore) (aws_ap_southeast_1)' },
    { id: 'aws_ap_southeast_2', name: 'Asia Pacific (Sydney) (aws_ap_southeast_2)' },
    { id: 'aws_ap_southeast_3', name: 'Asia Pacific (Jakarta) (aws_ap_southeast_3)' },
    { id: 'gcp_us_central1', name: 'US Central1 (Iowa) (gcp_us_central1)' },
    { id: 'gcp_us_east4', name: 'US East4 (N. Virginia) (gcp_us_east4)' },
    { id: 'gcp_europe_west2', name: 'Europe West2 (London) (gcp_europe_west2)' },
    { id: 'gcp_europe_west3', name: 'Europe West3 (Frankfurt) (gcp_europe_west3)' },
    { id: 'gcp_europe_west4', name: 'Europe West4 (Netherlands) (gcp_europe_west4)' },
    { id: 'gcp_me_central2', name: 'Middle East Central2 (Dammam) (gcp_me_central2)' },
    { id: 'azure_westus2', name: 'West US 2 (Washington) (azure_westus2)' },
    { id: 'azure_centralus', name: 'Central US (Iowa) (azure_centralus)' },
    { id: 'azure_southcentralus', name: 'South Central US (Texas) (azure_southcentralus)' },
    { id: 'azure_eastus2', name: 'East US 2 (Virginia) (azure_eastus2)' },
    { id: 'azure_canadacentral', name: 'Canada Central (Toronto) (azure_canadacentral)' },
    { id: 'azure_mexicocentral', name: 'Mexico Central (Mexico City) (azure_mexicocentral)' },
    { id: 'azure_uksouth', name: 'UK South (London) (azure_uksouth)' },
    { id: 'azure_northeurope', name: 'North Europe (Ireland) (azure_northeurope)' },
    { id: 'azure_westeurope', name: 'West Europe (Netherlands) (azure_westeurope)' },
    { id: 'azure_switzerlandnorth', name: 'Switzerland North (Zurich) (azure_switzerlandnorth)' },
    { id: 'azure_uaenorth', name: 'UAE North (Dubai) (azure_uaenorth)' },
    { id: 'azure_centralindia', name: 'Central India (Pune) (azure_centralindia)' },
    { id: 'azure_japaneast', name: 'Japan East (Tokyo) (azure_japaneast)' },
    { id: 'azure_southeastasia', name: 'Southeast Asia (Singapore) (azure_southeastasia)' },
    { id: 'azure_australiaeast', name: 'Australia East (New South Wales) (azure_australiaeast)' },
  ];
  const [accountRegion, setAccountRegion] = useState<SelectOption>(regionOptions[0]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [buildDateError, setBuildDateError] = useState<string>();
  const [deleteDateError, setDeleteDateError] = useState<string>();

  const generateSlug = (name: string) => {
    // Generate the slug and then truncate to max 31 characters
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-|-$/g, '')
      .slice(0, 31); // Limit to 31 characters max to prevent pipeline failures
  };

  const validateSlug = useCallback(
    async (slug: string) => {
      if (!slug) return;
      setIsValidating(true);
      try {
        await axios.get(`${dataopsAdminAppApiEndpoint}/event_management/${slug}`, {
          headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' },
        });
        setSlugError('This event ID already exists');
      } catch (error: any) {
        if (error.response?.status === 404) {
          setSlugError('');
        } else {
          setSlugError('Error checking event ID');
        }
      } finally {
        setIsValidating(false);
      }
    },
    [token],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (eventSlug) {
        void validateSlug(eventSlug);
      }
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [eventSlug, validateSlug]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setEventName(name);
    setEventSlug(generateSlug(name));
  };

  useEffect(() => {
    if (token) {
      // Dispatch the Redux thunk to fetch event projects
      dispatch(fetchEventProjects(token))
        .unwrap()
        .catch((error: any) => {
          console.error('Failed to fetch solutions:', error);
          setSolutionsError(error.message || 'Failed to fetch solutions');
        });
    }
  }, [token, dispatch]);

  const formatDateWithTimezone = (date: Date, hour: number): string => {
    if (!date) return dayjs().tz(timezone.id).format('YYYY-MM-DDTHH:mm:ssZ');
    // Create a dayjs object with the selected date in the user's timezone
    // .tz second argument to true to keep local time
    const localTime = dayjs(date).tz(timezone.id, true).hour(hour).minute(0).second(0).millisecond(0);
    // Format with timezone offset (e.g., +08:00 for Singapore)
    return localTime.format('YYYY-MM-DDTHH:mm:ssZ');
  };

  const validateDates = () => {
    setBuildDateError(undefined);
    setDeleteDateError(undefined);

    if (buildDateValue?.startDate && eventDateValue?.startDate) {
      const buildDate = new Date(buildDateValue.startDate);
      const eventStartDate = new Date(eventDateValue.startDate);

      if (buildDate > eventStartDate) {
        setBuildDateError('Build date must be before or equal to event start date');
        return false;
      }
    }

    if (deleteDateValue?.startDate && eventDateValue?.endDate) {
      const deleteDate = new Date(deleteDateValue.startDate);
      const eventEndDate = new Date(eventDateValue.endDate);

      if (deleteDate < eventEndDate) {
        setDeleteDateError('Delete date must be after or equal to event end date');
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    setError(undefined);
    setIsSubmitting(true);

    // Validate required fields
    if (!eventName) {
      setError('Event name is required');
      setIsSubmitting(false);
      return;
    }
    if (
      !buildDateValue?.startDate ||
      !eventDateValue?.startDate ||
      !eventDateValue?.endDate ||
      !deleteDateValue?.startDate
    ) {
      setError('All dates are required');
      setIsSubmitting(false);
      return;
    }
    if (!totalAttendees) {
      setError('Number of attendees is required');
      setIsSubmitting(false);
      return;
    }
    if (instructorAccounts && isNaN(parseInt(instructorAccounts))) {
      setError('Instructor accounts must be a valid number');
      setIsSubmitting(false);
      return;
    }

    if (!validateDates()) {
      setIsSubmitting(false);
      return;
    }

    if (!selectedSolution) {
      setError('Please select a solution');
      setIsSubmitting(false);
      return;
    }

    try {
      const buildDateTime = formatDateWithTimezone(buildDateValue.startDate, 5);
      const startDateTime = formatDateWithTimezone(eventDateValue.startDate, 7);
      const endDateTime = formatDateWithTimezone(eventDateValue.endDate, 22);
      const decommissionDateTime = formatDateWithTimezone(deleteDateValue.startDate, 23);

      const solutionId = selectedSolution.id;
      const solutionPath = selectedSolution.path;

      const requestBody: EventCreateRequest = {
        name: eventName,
        location: eventLocation,
        build_date: buildDateTime,
        start_date: startDateTime,
        end_date: endDateTime,
        decommission_date: decommissionDateTime,
        grant_reporter_access_to_configure_project: true,
        organization_account_identifier: snowflakeOrganizationAccounts[0],
        extra_env_vars: {
          DATAOPS_CATALOG_SALESFORCE_ID: salesForceId,
          INSTRUCTOR_ACCOUNTS: instructorAccounts || '0',
          SELECTED_SOLUTION: solutionId,
        },
        instructions,
        initial_pool_size: parseInt(totalAttendees) + parseInt(instructorAccounts || '0'),
        dataops_configure_project_path: solutionPath ?? '',
        dataops_configure_project_pipe_file: 'full-ci.yml',
        dataops_configure_project_git_ref: 'main',
        dataops_top_level_group_path: 'snowflake/event',
        snowflake_account_region_group: accountRegion.id,
        snowflake_account_edition: 'ENTERPRISE',
      };

      await axios.post(`${dataopsAdminAppApiEndpoint}/event_management/${eventSlug}`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'SSC-Role': role,
        },
      });

      navigate(`/event-deployments/${eventSlug}`);
    } catch (err: any) {
      console.error('Failed to create event:', err);
      setError(err.response?.data?.message || 'Failed to create event');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ImportColumnLayout>
      <div>
        <span
          className={classNames(
            'text-dataops-primary-light-blue',
            'inline-flex rounded-lg p-3 ring-1 ring-dataops-primary-light-blue',
          )}
        >
          <BoltIcon aria-hidden="true" className="h-6 w-6" />
        </span>
      </div>
      <h1 className="text-xl">New event</h1>
      <div className="flex space-x-1">
        <div className="inline-flex items-center px-1 space-x-1 text-sm text-gray-800 rounded-md ring-1 ring-inset cursor-default ring-gray-500/10">
          <BeakerIcon className="w-4 h-4" /> <span>Private Preview</span>
        </div>
        <div className="inline-flex items-center px-1 space-x-1 text-sm text-gray-800 rounded-md ring-1 ring-inset cursor-default ring-gray-500/10">
          <LockClosedIcon className="w-4 h-4" /> <span>Administrators only</span>
        </div>
      </div>
      <div className="space-y-20">
        <SolutionNewSection title="Analytics">
          <Input
            id="salesforceIdInput"
            data-testid="event-salesforce-id-input"
            label="Salesforce ID"
            icon={ChartPieIcon}
            optional
            help={<p>Enter a Salesforce ID to help report on event activity related to a customer.</p>}
            value={salesForceId}
            onChange={(e) => {
              const value = e.target.value;
              setSalesForceId(value);
              setSalesForceIdError(salesForceIdValidation(value, ValidationLevel.AllowEmpty));
            }}
            error={salesForceIdError}
          />
          <InputSelect
            id="eventDeliveryFormatInput"
            data-testid="event-delivery-format-input"
            label="Delivery format"
            icon={ChatBubbleLeftRightIcon}
            options={deliveryFormatOptions}
            value={deliveryFormat}
            onChange={setDeliveryFormat}
          />
          <InputSelect
            id="eventSolutionInput"
            data-testid="event-solution-input"
            label="Solution"
            icon={MagnifyingGlassIcon}
            options={eventProjects}
            value={selectedSolution}
            onChange={setSelectedSolution}
            loading={eventProjectsStatus === 'loading'}
            error={solutionsError ?? undefined}
            help={
              <div>
                <p>Select the solution to be deployed for this event.</p>
                {eventProjectsStatus === 'loading' && (
                  <p className="text-gray-500 italic">Loading solutions from GitLab...</p>
                )}
                {eventProjects.length === 0 && eventProjectsStatus !== 'loading' && !solutionsError && (
                  <p className="text-gray-500 italic">No solutions found in the snowflake/event namespace.</p>
                )}
              </div>
            }
          />
        </SolutionNewSection>
        <SolutionNewSection title="Event identity">
          <Input
            id="eventNameInput"
            data-testid="event-name-input"
            label="Event name"
            icon={TicketIcon}
            value={eventName}
            onChange={handleNameChange}
            help={<p>Your headline name for the event for attendees to recognize.</p>}
          />
          <Input
            id="eventSlugInput"
            data-testid="event-slug-input"
            label="Event unique ID"
            icon={FingerPrintIcon}
            value={eventSlug}
            onChange={(e) => {
              setEventSlug(generateSlug(e.target.value));
            }}
            error={slugError}
            loading={isValidating}
            help={
              <p>
                Used by attendees to get the correct event landing page (go.dataops.live/{eventSlug}).
                {eventSlug.length >= 31 && (
                  <>
                    <br />
                    <span className="text-blue-500">
                      Limited to 31 characters due to Snowflake account name max length.
                    </span>
                  </>
                )}
              </p>
            }
          />
        </SolutionNewSection>
        <SolutionNewSection title="Event details">
          <InputSelect
            id="eventTimezoneInput"
            data-testid="event-timezone-input"
            label="Timezone"
            icon={GlobeAltIcon}
            options={timezoneOptions}
            value={timezone}
            onChange={setTimezone}
            help={<p>All event times will be in this timezone. Current offset: UTC{getTimezoneOffset(timezone.id)}</p>}
          />
          <InputSelect
            id="accountRegionInput"
            data-testid="account-region-input"
            label="Account Region"
            icon={GlobeAltIcon}
            options={regionOptions}
            value={accountRegion}
            onChange={setAccountRegion}
            help={<p>Select the region where Snowflake accounts will be provisioned.</p>}
          />
          <Input
            id="eventLocationInput"
            data-testid="event-location-input"
            label="Location"
            icon={MapPinIcon}
            value={eventLocation}
            onChange={(e) => {
              setEventLocation(e.target.value);
            }}
            placeholder="Virtual"
            help={<p>Used on the event landing page and can be used in event documentation.</p>}
          />
          <InputDatepicker
            id="eventBuildDateInput"
            dataTestid="event-build-date-input"
            label="When to build Snowflake accounts"
            dateValue={buildDateValue}
            setDateValue={(value) => {
              setBuildDateValue(value);
              setBuildDateError(undefined);
            }}
            error={buildDateError}
            asSingle={true}
            useRange={false}
            minDate={minDate}
            maxDate={maxDate}
            icon={CalendarDaysIcon}
            help={<p>You can delay the Snowflake account creation until this date.</p>}
          />
          <InputDatepicker
            id="eventStartEndDateInput"
            dataTestid="event-start-end-date-input"
            label="When event starts and finishes"
            dateValue={eventDateValue}
            setDateValue={(value) => {
              setEventDateValue(value);
              // Validate dates whenever event dates change
              setTimeout(validateDates, 0);
            }}
            asSingle={false}
            useRange={true}
            minDate={minDate}
            maxDate={maxDate}
            icon={CalendarDaysIcon}
          />
          <Input
            id="eventTotalAttendeesInput"
            data-testid="event-total-attendees-input"
            label="Maximum expected number of attendees"
            icon={HashtagIcon}
            type="number"
            min={1}
            value={totalAttendees}
            onChange={(e) => {
              setTotalAttendees(e.target.value);
            }}
            suggestions={['10', '25', '50', '100']}
            onSuggestionClick={(value) => {
              setTotalAttendees(value);
            }}
          />
          <Input
            id="eventInstructorAccountsInput"
            data-testid="event-instructor-accounts-input"
            label="Maximum expected instructor practice accounts"
            icon={HashtagIcon}
            type="number"
            min={0}
            value={instructorAccounts}
            onChange={(e) => {
              setInstructorAccounts(e.target.value);
            }}
            suggestions={['1', '2', '5', '10']}
            onSuggestionClick={(value) => {
              setInstructorAccounts(value);
            }}
            help={
              <p>
                Total Snowflake accounts to be created:{' '}
                {parseInt(totalAttendees || '0') + parseInt(instructorAccounts || '0')}
              </p>
            }
          />
        </SolutionNewSection>
        <SolutionNewSection title="Instructions">
          <MarkdownEditor
            id="eventInstructionsInput"
            testId="event-instructions-input"
            label="Event Instructions"
            required
            value={instructions}
            eventName={eventName}
            onChange={setInstructions}
            help={<p>Enter markdown instructions for the event. This will be displayed on the event landing page.</p>}
          />
        </SolutionNewSection>
        <SolutionNewSection title="Tidy up">
          <InputDatepicker
            id="eventDeleteDateInput"
            dataTestid="event-delete-date-input"
            label="When to delete Snowflake accounts"
            dateValue={deleteDateValue}
            setDateValue={(value) => {
              setDeleteDateValue(value);
              setDeleteDateError(undefined);
            }}
            error={deleteDateError}
            asSingle={true}
            useRange={false}
            minDate={minDate}
            maxDate={maxDate}
            icon={CalendarDaysIcon}
          />
        </SolutionNewSection>
        <div id="controls" className="space-y-4">
          <div className="flex w-1/2 items-center justify-center">
            <div className="flex-grow  border-b border-gray-300"></div>
          </div>
          {error && <div className="text-sm text-red-600 bg-red-50 p-4 rounded-md">{error}</div>}
          <div className="flex space-x-4">
            <Button
              id="backButton"
              data-testid="event-back-button"
              intent={'light'}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
            <Button
              id="createButton"
              data-testid="event-create-button"
              disabled={!canPublish || isSubmitting}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </ImportColumnLayout>
  );
}

function SolutionNewSection({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <section>
      <div className="flex items-center justify-center">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-4 text-center max-w-xs text-lg text-gray-400 hover:cursor-default">{title}</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div className="space-y-4">{children}</div>
    </section>
  );
}
