import React from 'react';

interface Props {
  allocatedPercentage: number;
  label?: string;
}

export default function EventAccountPoolAllocationBar({ allocatedPercentage, label }: Props) {
  return (
    <div>
      <div className="flex mb-2 items-center justify-between">
        {label && <div>{label}</div>}
        <div>
          <span className="text-xs font-semibold inline-block">{allocatedPercentage.toFixed(0)}% allocated</span>
        </div>
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-300">
        <div
          style={{ width: `${allocatedPercentage}%` }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
            allocatedPercentage === 0 ? 'bg-gray-300' : 'bg-dataops-primary-dark-blue'
          }`}
        ></div>
      </div>
    </div>
  );
}
