import React, { useState } from 'react';
// import axios from 'axios';
// import { UserIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { useToken } from '../../hooks/useToken';
import { sendEvent } from '../../utilities/analytics';
import Spinner from '../common/Spinner';
// import { getConfig } from '../../config/config-helper';

interface Props {
  eventSlug: string;
  size?: 'medium' | 'large';
  className?: string;
}

// placeholder code for coming soon - instructor add endpoint not yet created in admin app

export default function AddInstructorButton({ eventSlug, size = 'medium', className = '' }: Props) {
  const token = useToken();
  const [disabled, setDisabled] = useState(true);
  const [adding, setAdding] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  // const { dataopsAdminAppApiEndpoint } = getConfig();

  async function addAttendeeClickHandler() {
    sendEvent({ name: 'instructor-add-start', properties: { eventSlug } });
    setDisabled(true);
    setAdding(true);
    setErrors([]);
    console.log(`http://localhost:8000/api/v1/event_management/${eventSlug}/addInstructor`);
    console.log('token', token);
    // const url = `${dataopsAdminAppApiEndpoint}/event_management/${eventSlug}/addInstructor`;
    try {
      // Placeholder for the actual API call
      // await axios.post(`${url}`, {
      //   headers: {
      //     authorization: `Bearer ${token}`,
      //     'SSC-Role': 'admin',
      //   },
      //   withCredentials: true,
      // });
      // Dispatch any necessary actions here
    } catch (err: any) {
      console.log('Failed to add attendee: ', err);
      setErrors([err.response?.data?.reason || 'Failed to add attendee']);
    } finally {
      setDisabled(false);
      setAdding(false);
    }
  }

  const buttonText = adding ? 'Adding' : 'Add Instructor (coming soon)';

  return (
    <div className="flex flex-col">
      <Button
        intent={'light'}
        size={size}
        className={`z-10 ${className}`}
        onClick={() => {
          addAttendeeClickHandler().catch(() => {
            console.error('Error adding instructor:', eventSlug);
          });
        }}
        disabled={disabled}
      >
        {adding ? <Spinner size="small" className="mr-1" /> : ''}
        {buttonText}
      </Button>
      <div className="text-xs">{errors}</div>
    </div>
  );
}
