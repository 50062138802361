import { gql } from '@apollo/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from '../apolloClient';
import { parseGuid } from '../utilities/parsers';
import axios, { type AxiosError } from 'axios';
import { getConfig } from '../config/config-helper';
import { parse } from 'yaml';
import { type ISnowflakeDataShare } from '../interfaces/IDeployRequest';
import { getProject } from '../utilities/projects';

// #region Interfaces

export interface IEnvironment {
  id: string;
  guid: string;
}

export interface ISolution {
  id: string;
  guid: string;
  name: string;
  fullPath: string;
  listing: IListing;
  starCount: number;
  avatarUrl: string | undefined;
  // --- Extra ---
  requestExtraStatus: 'empty' | 'filled' | 'loading' | 'failed';
  environments?: IEnvironment[];
}

export interface IListing {
  listing_kind?: string;
  tier: number;
  name?: string;
  version?: string;
  description?: string;
  creator?: string;
  icon?: string;
  use_case?: string[];
  snowflake_feature?: string[];
  partner?: string[];
  vertical_applicability?: string[];
  workload?: string[];
  event?: string[];
  vertical_themes?: string[];
  pre_instance_setup_message?: string;
  pre_instance_setup_checklist?: string[];
  required_data_shares?: ISnowflakeDataShare[];
  certified?: boolean;
  customer_name_suffix?: string;
  external_url?: string;
  help?: string;
  preview?: string;
}

interface ISolutionsState {
  solutions: ISolution[];
  solutionDrafts: ISolution[];
  requestStatus?: 'idle' | 'loading' | 'failed';
}

const initialState: ISolutionsState = {
  solutionDrafts: [],
  solutions: [],
};

async function getFileContent(baseUrl: string, token: string, iid: string, fileName: string): Promise<string> {
  try {
    const { data } = await axios.get(`${baseUrl}/${iid}/repository/files/${fileName}?ref=main`, {
      headers: { authorization: `Bearer ${token}` },
    });
    return data.content;
  } catch (error) {
    const data = (error as AxiosError).response?.data;
    console.error(`getFileContent error for ${fileName}:`, data);
    return '';
  }
}

function decodeBase64(content: string): string {
  try {
    return atob(content);
  } catch (error) {
    console.error('decodeBase64 error:', error);
    return '';
  }
}

function parseListingFile(listingFileContent: string): any {
  try {
    const decoded = decodeBase64(listingFileContent);
    const parsed = parse(decoded);

    const keys = ['name', 'creator', 'description', 'version'];
    const parsedKeys = Object.keys(parsed);
    const missingKeys = keys.filter((k) => !parsedKeys.includes(k));
    if (missingKeys.length > 0) {
      console.error('Missing listing keys:', missingKeys.map((k) => `'${k}'`).join(', '));
    }
    return parsed;
  } catch (error) {
    console.error('parseListingFile error:', error);
    return {
      name: '',
      description: '',
      creator: '',
      tier: 0,
    };
  }
}

function getTier(tier: number | undefined, certified: boolean | undefined) {
  if (tier !== undefined) {
    return tier;
  }
  if (certified === true) {
    return 1;
  }
  return 0;
}

// #region Fetch solution
export const fetchSolution = createAsyncThunk(
  'solution/fetch',
  async (props: { token: string; fullPath: string; iid?: string }): Promise<ISolution> => {
    const { token, iid } = props;
    // console.log('Fetching solution:', props);
    if (token === '') {
      throw new Error('Token not set');
    }
    const { dataopsliveBaseUrl } = getConfig();
    const urlBase = `${dataopsliveBaseUrl}/api/v4/projects`;
    let fullPath = props.fullPath;
    if (iid !== undefined) {
      const data = await getProject(urlBase, token, iid);
      fullPath = data.path_with_namespace;
    }

    const GET_SOLUTION = gql`
      query getSolution {
        project(fullPath: "${fullPath}") {
          id
          name
          fullPath
          starCount
          avatarUrl
          environments {
            nodes {
              id
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      }
    `;
    const solutionFetchResponse = await client.query({
      query: GET_SOLUTION,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getSolution' } },
    });

    if (solutionFetchResponse.data?.project === undefined) {
      throw new Error('Solution not found');
    }
    console.log(
      `Solution ${solutionFetchResponse.data.project.id as string} fetch response`,
      solutionFetchResponse.data.project.avatarUrl,
    );

    const listingFile = await getFileContent(
      urlBase,
      token,
      parseGuid(solutionFetchResponse.data.project.id),
      'listing.yml',
    );
    const listing: IListing = await parseListingFile(listingFile);
    listing.tier = getTier(listing.tier, listing.certified);
    if (listing.name === '') {
      listing.name = solutionFetchResponse.data.project.name;
    }

    const previewFile = await getFileContent(
      urlBase,
      token,
      parseGuid(solutionFetchResponse.data.project.id),
      'PREVIEW.md',
    );
    listing.preview = decodeBase64(previewFile);

    const helpFile = await getFileContent(urlBase, token, parseGuid(solutionFetchResponse.data.project.id), 'HELP.md');
    listing.help = decodeBase64(helpFile);

    return {
      id: parseGuid(solutionFetchResponse.data.project.id),
      guid: solutionFetchResponse.data.project.id,
      name: solutionFetchResponse.data.project.name,
      fullPath: solutionFetchResponse.data.project.fullPath,
      listing,
      starCount: solutionFetchResponse.data.project.starCount,
      avatarUrl: solutionFetchResponse.data.project.avatarUrl,
      requestExtraStatus: 'filled',
      environments: solutionFetchResponse.data.project.environments.nodes.map((env: any) => ({
        id: parseGuid(env.id),
        guid: env.id,
      })),
    };
  },
);

// #region Fetch solution-drafts
export const fetchSolutionDrafts = createAsyncThunk(
  'solutionDrafts/fetch',
  async (props: { token: string; searchTerm?: string }): Promise<ISolution[]> => {
    const { token, searchTerm } = props;
    const GET_SOLUTION = gql`
      query getSolutionDrafts {
        group(fullPath: "snowflake/solution-drafts") {
          projects(sort: SIMILARITY, search: "${searchTerm}") {
            nodes {
              id
              name
              fullPath
            }
          }
        }
      }
    `;
    const solutionDraftsFetchResponse = await client.query({
      query: GET_SOLUTION,
      context: { headers: { authorization: `Bearer ${token}`, 'DataOps-Operation-Name': 'getSolutionDrafts' } },
    });

    const solutions: ISolution[] = [];
    const solutionDraftsGroup = solutionDraftsFetchResponse.data.group;
    try {
      if (solutionDraftsGroup !== null) {
        const solutionsMap = solutionDraftsGroup.projects.nodes.map(async (project: any) => {
          return {
            id: parseGuid(project.id),
            guid: project.id,
            fullPath: project.fullPath,
            starCount: project.starCount,
          };
        });
        solutions.push(...(await Promise.all(solutionsMap)));
      }
    } catch (error) {
      console.error('Error fetching solution drafts:', error);
    }
    return solutions;
  },
);

// #region Reducer
export const solutionsSlice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {
    deleteDeployment: (state, action) => {
      state.solutions = state.solutions.filter((s) => s.guid !== action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSolution.pending, (state, action) => {
        state.requestStatus = 'loading';
        const solution = state.solutions.find((s) => s.id === action.meta.arg.iid);
        if (solution !== undefined) {
          solution.requestExtraStatus = 'loading';
        } else {
          state.solutions.push({
            id: action.meta.arg.iid ?? '',
            name: '',
            environments: [],
            guid: '',
            fullPath: '',
            listing: {
              name: '',
              description: '',
              creator: '',
              tier: 0,
            },
            starCount: 0,
            avatarUrl: undefined,
            requestExtraStatus: 'loading',
          });
        }
      })
      .addCase(fetchSolution.fulfilled, (state, action) => {
        const solution = state.solutions.find((s) => s.id === action.payload.id);
        if (solution !== undefined) {
          solution.name = action.payload.name;
          solution.guid = action.payload.guid;
          solution.fullPath = action.payload.fullPath;
          solution.starCount = action.payload.starCount;
          solution.avatarUrl = action.payload.avatarUrl;
          solution.listing = action.payload.listing;
          solution.requestExtraStatus = 'filled';
          solution.environments = action.payload.environments;
        } else {
          state.solutions.push(action.payload);
        }
        state.requestStatus = 'idle';
      })
      .addCase(fetchSolution.rejected, (state, action) => {
        state.requestStatus = 'failed';
        const solution = state.solutions.find((s) => s.id === action.meta.arg.iid);
        if (solution !== undefined) {
          solution.requestExtraStatus = 'failed';
        }
      });
    builder
      .addCase(fetchSolutionDrafts.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(fetchSolutionDrafts.fulfilled, (state, action) => {
        state.solutionDrafts = action.payload;
        state.requestStatus = 'idle';
      })
      .addCase(fetchSolutionDrafts.rejected, (state) => {
        state.requestStatus = 'failed';
      });
  },
});

export const { deleteDeployment } = solutionsSlice.actions;

export const selectSolution = (state: { solutions: ISolutionsState }, projectId: string): ISolution => {
  return state.solutions.solutions.find((s) => s.id === projectId) as ISolution;
};

export const selectSolutionDrafts = (state: { solutions: ISolutionsState }): ISolution[] => {
  return state.solutions.solutionDrafts;
};

export const selectSolutionInDrafts = (state: { solutions: ISolutionsState }, projectId: string): boolean => {
  return state.solutions.solutionDrafts.some((s) => s.id === projectId);
};

export const selectRequestStatus = (state: { solutions: ISolutionsState }): string | undefined => {
  return state.solutions.requestStatus;
};

export default solutionsSlice.reducer;
