import React from 'react';

export default function ProjectCardSkeleton() {
  return (
    <li className="relative h-full col-span-1 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50">
      <div className="flex h-full w-full items-start justify-between space-x-6 p-6">
        {/* Project Icon Skeleton */}
        <div className="w-10 h-10 bg-gray-200 rounded-full animate-pulse"></div>

        {/* Content Skeleton */}
        <div className="flex-1 truncate">
          {/* Project Name Skeleton */}
          <div className="flex items-center space-x-3">
            <div className="w-40 h-6 bg-gray-200 rounded animate-pulse"></div>
          </div>

          {/* Tags Skeleton */}
          <div className="flex mt-2 space-x-1">
            <div className="w-20 h-5 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-16 h-5 bg-gray-200 rounded animate-pulse"></div>
          </div>

          {/* Description Skeleton */}
          <div className="flex mt-4 items-center space-x-1">
            <div className="w-full h-8 bg-gray-200 rounded animate-pulse"></div>
          </div>
        </div>

        {/* Button Skeleton */}
        <div className="w-20 h-8 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </li>
  );
}
