import React from 'react';
import { classNames } from '../../utilities/styleUtils';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  customRef?: React.Ref<HTMLInputElement>;
  label?: string;
  optional?: boolean;
  help?: React.ReactNode;
  icon?: React.ElementType;
  error?: string;
  loading?: boolean;
  suggestions?: string[];
  onSuggestionClick?: (suggestion: string) => void;
}

export function Input({
  customRef = undefined,
  label = undefined,
  optional = false,
  icon = undefined,
  help = undefined,
  error = undefined,
  loading = false,
  suggestions = [],
  onSuggestionClick,
  ...props
}: InputProps) {
  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={props.id ?? 'input'}
            className={classNames(
              'mb-2 block text-sm font-medium leading-6',
              loading ? 'text-gray-400' : 'text-gray-900',
            )}
          >
            {label}
          </label>
        )}
        {optional && (
          <span id={`${props.id ?? 'input'}-optional`} className="mb-2 text-sm leading-6 text-gray-500 cursor-default">
            Optional
          </span>
        )}
      </div>
      <div className="space-y-2">
        <div className="relative">
          <input
            ref={customRef}
            className={classNames(
              'h-[33px] w-full border bg-white px-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm outline-none outline-offset-0 focus:outline focus:outline-4 transition-all ease-in-out',
              icon ? 'pl-11' : '',
              help ?? error ? 'rounded-t-md' : 'rounded-md',
              error
                ? 'border-red-300 focus:outline-red-200'
                : 'border-gray-200 focus:outline-dataops-primary-light-blue/20',
              loading ? 'bg-gray-50 text-gray-400 cursor-wait' : '',
            )}
            readOnly={loading}
            {...props}
          />
          {icon &&
            React.createElement(icon, {
              className: classNames(
                'pointer-events-none absolute left-3 top-2 h-5 w-5',
                loading ? 'text-gray-300' : 'text-gray-500',
              ),
            })}
          {loading && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-gray-400" />
            </div>
          )}
          {suggestions.length > 0 && (
            <div className="absolute inset-y-1 right-12 flex justify-end gap-2">
              {suggestions.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => onSuggestionClick?.(suggestion)}
                  className={classNames(
                    'px-2 min-w-12 h-[25px] text-xs rounded-md border border-gray-200 bg-dataops-supporting-gray',
                    'hover:border-dataops-primary-light-blue hover:bg-dataops-primary-light-blue/5',
                    'transition-colors duration-100 cursor-pointer',
                  )}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div>
        {error && <div className="w-full px-4 py-2 text-sm text-red-600 bg-red-50">{error}</div>}
        {help && (
          <div className="w-full px-4 py-2 leading-5 space-y-2 text-sm text-gray-700 bg-dataops-primary-dark-blue/5 rounded-b-md">
            {help}
          </div>
        )}
      </div>
    </div>
  );
}
