import React from 'react';
import dayjs from 'dayjs';
import { type IChildAccount } from '../../interfaces/IChildAccount';
import ChildAccountStatus from './ChildAccountStatus';
import ReconfigureAccountButton from '../controls/ReconfigureAccountButton';
import { dateTimeFormat } from '../../utilities/constants';

interface AccountsTableProps {
  accounts: IChildAccount[];
  canPublish: boolean;
  eventSlug?: string;
}

const AccountsTable: React.FC<AccountsTableProps> = ({ accounts, canPublish, eventSlug }) => {
  if (accounts.length === 0) {
    return null;
  }

  return (
    <div className="mt-2">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300 table-fixed">
          <colgroup>
            <col className="w-1/4" />
            <col className="w-1/6" />
            <col className="w-1/4" />
            <col className="w-1/6" />
            {canPublish && <col className="w-1/6" />}
          </colgroup>
          <thead>
            <tr>
              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Account ID</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigned To</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Allocated At</th>
              {canPublish && <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {accounts.map((account, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account.slug}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <ChildAccountStatus status={account.status} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {account.allocated_to ?? 'Unassigned'}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {account.allocated_at ? dayjs(account.allocated_at).format(dateTimeFormat) : 'N/A'}
                </td>
                {canPublish && (
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <ReconfigureAccountButton
                      account={account}
                      eventSlug={eventSlug}
                      intent="primary"
                      className="hover:bg-dataops-primary-dark-blue/90 transition-colors"
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountsTable;
