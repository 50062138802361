import React, { useState } from 'react';
import axios from 'axios';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { useToken } from '../../hooks/useToken';
import { sendEvent } from '../../utilities/analytics';
import Spinner from '../common/Spinner';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getConfig } from '../../config/config-helper';
import { fetchEvent } from '../../reducers/eventsSlice';
import { useAppDispatch } from '../../hooks';

interface Props {
  eventSlug: string;
  size?: 'medium' | 'mediumPlus' | 'large';
  className?: string;
}

export default function ApproveButton({ eventSlug, size = 'medium', className = '' }: Props) {
  const dispatch = useAppDispatch();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const token = useToken();
  const [disabled, setDisabled] = useState(false);
  const [approving, setApproving] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const { dataopsAdminAppApiEndpoint } = getConfig();
  const url = `${dataopsAdminAppApiEndpoint}/event_management/${eventSlug}/approve`;

  async function approveEventClickHandler() {
    sendEvent({ name: 'event-approve-start', properties: { eventSlug } });
    setDisabled(true);
    setApproving(true);
    setErrors([]);
    try {
      await axios.post(
        `${url}`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            'SSC-Role': role,
          },
        },
      );
      dispatch(fetchEvent({ token, role, slug: eventSlug })).catch((err: unknown) => err);
    } catch (err: any) {
      console.log('Failed to approve event: ', err);
      setErrors([err.response?.data?.reason || 'Failed to approve event']);
    } finally {
      setDisabled(false);
      setApproving(false);
    }
  }

  const buttonText = approving ? 'Approving' : 'Approve';

  return (
    <div className="flex flex-col">
      <Button
        intent={'primary'}
        size={size}
        className={`z-10`}
        onClick={() => {
          approveEventClickHandler().catch(() => {
            console.error('Error approving event:', eventSlug);
          });
        }}
        disabled={disabled}
      >
        {approving ? <Spinner size="small" className="mr-1" /> : <CheckCircleIcon className="w-4 h-4 mr-1" />}
        {buttonText}
      </Button>
      <div className="text-xs">{errors}</div>
    </div>
  );
}
