/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { XCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { useToken } from '../../hooks/useToken';
import { sendEvent } from '../../utilities/analytics';
import Spinner from '../common/Spinner';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getConfig } from '../../config/config-helper';
import { Input } from './Input';
import { fetchEvent } from '../../reducers/eventsSlice';
import { useAppDispatch } from '../../hooks';

interface Props {
  eventSlug: string;
  size?: 'medium' | 'mediumPlus' | 'large';
  className?: string;
}

export default function RejectButton({ eventSlug, size = 'medium', className = '' }: Props) {
  const dispatch = useAppDispatch();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const token = useToken();
  const [disabled, setDisabled] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { dataopsAdminAppApiEndpoint } = getConfig();
  const url = `${dataopsAdminAppApiEndpoint}/event_management/${eventSlug}/reject`;

  useEffect(() => {
    if (textInputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [textInputVisible]);

  async function handleReject(reason: string) {
    if (!reason.trim()) {
      setErrors(['Rejection reason is required']);
      return;
    }
    sendEvent({ name: 'event-reject-start', properties: { eventSlug } });
    setDisabled(true);
    setRejecting(true);
    setErrors([]);
    try {
      await axios.post(
        `${url}`,
        { rejection_reason: reason },
        { headers: { authorization: `Bearer ${token}`, 'SSC-Role': role } },
      );
      setTextInputVisible(false);
      setRejectionReason('');
      dispatch(fetchEvent({ token, role, slug: eventSlug })).catch((err: unknown) => err);
    } catch (err: any) {
      console.log('Failed to reject event: ', err);
      setErrors([err.response?.data?.message || 'Failed to reject event']);
    } finally {
      setDisabled(false);
      setRejecting(false);
    }
  }

  const handleCancel = () => {
    setTextInputVisible(false);
    setRejectionReason('');
    setErrors([]);
  };

  if (textInputVisible) {
    return (
      <div className="flex flex-col gap-2">
        <Input
          customRef={inputRef}
          type="text"
          value={rejectionReason}
          onChange={(e) => {
            setRejectionReason(e.target.value);
          }}
          placeholder="Enter rejection reason"
          disabled={disabled}
          error={errors.length > 0 ? errors[0] : undefined}
          loading={rejecting}
        />
        <div className="flex gap-2">
          <Button intent="light" size={size} onClick={handleCancel} disabled={disabled}>
            <ArrowLeftIcon className="w-4 h-4 mr-1" />
            Cancel
          </Button>
          <Button
            intent="danger"
            size={size}
            onClick={() => {
              void handleReject(rejectionReason);
            }}
            disabled={disabled}
          >
            {rejecting ? <Spinner size="small" className="mr-1" /> : <XCircleIcon className="w-4 h-4 mr-1" />}
            Reject with reason
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Button
      intent="danger"
      size={size}
      className={className}
      onClick={() => {
        setTextInputVisible(true);
      }}
      disabled={disabled}
    >
      <XCircleIcon className="w-4 h-4 mr-1" />
      Reject
    </Button>
  );
}
