import React from 'react';
import { type IChildAccount } from '../../interfaces/IChildAccount';
import AccountsTable from './AccountsTable';

interface AccountsSectionProps {
  accounts: IChildAccount[];
  title: string;
  canPublish: boolean;
  eventSlug?: string;
}

const AccountsSection: React.FC<AccountsSectionProps> = ({ accounts, title, canPublish, eventSlug }) => {
  if (accounts.length === 0) {
    return null;
  }

  return (
    <li className="space-y-2 rounded-md px-2 py-4 bg-dataops-supporting-gray/40 list-none">
      <div className="flex items-center justify-between">
        <h2 className="flex-grow px-2 text-md text-dataops-secondary-dark-blue">{title}</h2>
        <div className="flex justify-center items-center w-6 h-6 mr-2 rounded-full bg-dataops-primary-dark-blue/10 text-xs">
          {accounts.length}
        </div>
      </div>
      <AccountsTable accounts={accounts} canPublish={canPublish} eventSlug={eventSlug} />
    </li>
  );
};

export default AccountsSection;
