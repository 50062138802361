import React from 'react';
import { type ResultViewProps } from '@elastic/react-search-ui-views';
import { type SearchResult } from '@elastic/search-ui';
import ProjectIcon from '../common/ProjectIcon';
import Tag from '../common/Tag';
import TierTag from './TierTag';
import NewDeploymentButton from '../controls/NewDeploymentButton';
import { UserIcon } from '@heroicons/react/16/solid';
import { HyperlinkOpenButton } from '../controls/HyperlinkOpenButton';
import { type ISolution } from '../../reducers/solutionsSlice';
import { type IListing } from '../../interfaces/IListing';

interface Props extends ResultViewProps {
  result: SearchResult;
}

export default function Result({ result }: Props) {
  const listing: IListing = {
    id: result?.id?.raw,
    dataopsProjectId: result?.dataops_project_id?.raw,
    projectMainUrl: result?.project_main_url?.raw,
    listingKind: result?.listing_kind?.raw ?? 'solution',
    name: result?.name?.raw,
    version: result?.version?.raw,
    description: result?.description?.raw,
    creator: result?.creator?.raw,
    icon: result?.icon?.raw,
    releaseStatus: result?.release_status?.raw,
    useCase: result?.use_case?.raw,
    snowflakeFeature: result?.snowflake_feature?.raw,
    partner: result?.partner?.raw,
    verticalApplicability: result?.vertical_applicability?.raw,
    workload: result?.workload?.raw,
    event: result?.event?.raw,
    verticalThemes: result?.vertical_themes?.raw,
    preInstanceSetupMessage: result?.pre_instance_setup_message?.raw,
    preInstanceSetupChecklist: result?.pre_instance_setup_checklist?.raw,
    requiredDataShares: result?.required_data_shares?.raw,
    certified: result?.certified?.raw,
    customerNameSuffix: result?.customer_name_suffix?.raw,
    tier: result?.tier?.raw,
    externalUrl: result?.external_url?.raw,
    preview: result?.preview?.raw,
    help: result?.help?.raw,
  };

  // Create a minimal solution object using the search result data directly
  // This avoids making an extra API call for data we already have
  const minimalSolution: ISolution = {
    id: `${listing.dataopsProjectId}`,
    guid: `${listing.dataopsProjectId}`,
    name: listing.name,
    fullPath: listing.projectMainUrl || '',
    listing: {
      name: listing.name,
      description: listing.description,
      creator: listing.creator,
      tier: listing.tier,
      certified: listing.certified,
      help: listing.help,
      preview: listing.preview,
    },
    starCount: 0,
    avatarUrl: listing.icon,
    requestExtraStatus: 'filled',
  };

  switch (listing.listingKind) {
    case 'solution':
      return <SolutionResult listing={listing} solution={minimalSolution} />;
    case 'event_solution':
      return <EventSolutionResult listing={listing} solution={minimalSolution} />;
    case 'hyperlink':
      return <HyperlinkResult listing={listing} solution={minimalSolution} />;
    default:
      return null;
  }
}

function SolutionResult({ listing, solution }: { listing: IListing; solution: ISolution }) {
  const solutionPageUrl = `/solutions/${listing.dataopsProjectId}`;
  const avatarUrl = solution?.avatarUrl ?? listing.icon;
  return (
    <li
      key={solution?.id}
      className="relative h-full col-span-1 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
      data-testid="search-result-item"
    >
      <div className="flex h-full w-full items-start justify-between space-x-6 p-6">
        <ProjectIcon iconSrc={avatarUrl} />
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <p className="text-md font-normal text-gray-900">
              <a href={solutionPageUrl}>
                <span className="absolute inset-x-0 -top-px bottom-0" />
                <span className="overflow-hidden text-ellipsis">{listing.name}</span>
              </a>
            </p>
          </div>
          <div className="flex mt-2 space-x-1">
            <Tag color={'lightGray'}>
              <UserIcon className="w-3 h-3 mr-1" />
              <span>{listing.creator}</span>
            </Tag>
            <TierTag tier={listing.tier} />
          </div>
          <div className="flex mt-4 items-center space-x-1">
            {listing.description && (
              <p className="text-xs text-gray-500 line-clamp-2 text-wrap">{listing.description}</p>
            )}
          </div>
        </div>
        {/* Required due to some listings missing a listingKind. */}
        {listing.tier < 4 ? (
          <NewDeploymentButton solution={solution} size="medium" buttonText="Try it" />
        ) : (
          <HyperlinkOpenButton solution={solution} size="medium" />
        )}
      </div>
    </li>
  );
}

function EventSolutionResult({ listing, solution }: { listing: IListing; solution: ISolution }) {
  // FIXME: This currently links to the new form, it should link to the event solution detail page.
  const solutionPageUrl = `/event-solutions/${listing.dataopsProjectId}/new`;
  const avatarUrl = solution?.avatarUrl ?? listing.icon;
  return (
    <li
      key={solution?.id}
      className="relative h-full col-span-1 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
      data-testid="search-result-item"
    >
      <div className="flex h-full w-full items-start justify-between space-x-6 p-6">
        <ProjectIcon iconSrc={avatarUrl} />
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <p className="text-md font-normal text-gray-900">
              <a href={solutionPageUrl}>
                <span className="absolute inset-x-0 -top-px bottom-0" />
                <span className="overflow-hidden text-ellipsis">{listing.name}</span>
              </a>
            </p>
          </div>
          <div className="flex mt-2 space-x-1">
            <Tag color={'lightGray'}>
              <UserIcon className="w-3 h-3 mr-1" />
              <span>{listing.creator}</span>
            </Tag>
            <TierTag tier={listing.tier} />
          </div>
          <div className="flex mt-4 items-center space-x-1">
            {listing.description && (
              <p className="text-xs text-gray-500 line-clamp-2 text-wrap">{listing.description}</p>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

function HyperlinkResult({ listing, solution }: { listing: IListing; solution: ISolution }) {
  const solutionPageUrl = `/solutions/${listing.dataopsProjectId}`;
  const avatarUrl = solution?.avatarUrl ?? listing.icon;
  return (
    <li
      key={solution?.id}
      className="relative h-full col-span-1 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
      data-testid="search-result-item"
    >
      <div className="flex h-full w-full items-start justify-between space-x-6 p-6">
        <ProjectIcon iconSrc={avatarUrl} />
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <p className="text-md font-normal text-gray-900">
              <a href={solutionPageUrl}>
                <span className="absolute inset-x-0 -top-px bottom-0" />
                <span className="overflow-hidden text-ellipsis">{listing.name}</span>
              </a>
            </p>
          </div>
          <div className="flex mt-2 space-x-1">
            <Tag color={'lightGray'}>
              <UserIcon className="w-3 h-3 mr-1" />
              <span>{listing.creator}</span>
            </Tag>
            <TierTag tier={listing.tier} />
          </div>
          <div className="flex mt-4 items-center space-x-1">
            {listing.description && (
              <p className="text-xs text-gray-500 line-clamp-2 text-wrap">{listing.description}</p>
            )}
          </div>
        </div>
        <HyperlinkOpenButton solution={solution} size="medium" />
      </div>
    </li>
  );
}
