/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { fetchEvent, selectEventBySlug } from '../../reducers/eventsSlice';
import MarkdownEditor from '../controls/MarkdownEditor';
import { getConfig } from '../../config/config-helper';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import Grid3Layout from '../layout/Grid3Layout';
import SectionLayout from '../layout/SectionLayout';
import { type RootState } from '../../store';
import RejectButton from '../controls/EventRejectionButton';
import ApproveButton from '../controls/EventApprovalButton';
import dayjs from 'dayjs';
import AddInstructorButton from '../controls/EventAddInstructorButton';
import { useToken } from '../../hooks/useToken';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useNavigationTab } from '../../hooks/useNavigationTab';
import Button from '../controls/Button';
import EventAccountPoolAllocationBar from '../event/EventAccountPoolAllocationBar';
import NoMatchEvents from './NoMatchEvents';
import {
  CircleStackIcon,
  UserIcon,
  ChartBarSquareIcon,
  ListBulletIcon,
  InformationCircleIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import PoolStatus from '../event/PoolStatus';
import { dateFormat, dateTimeFormat } from '../../utilities/constants';

export default function EventDeploymentPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventSlug } = useParams();
  const event = useSelector((state: RootState) => (eventSlug ? selectEventBySlug(state, eventSlug) : undefined));
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const token = useToken();
  const { dataopsliveBaseUrl, eventGoApplicationBaseUrl } = getConfig();

  useEffect(() => {
    if (eventSlug) {
      dispatch(fetchEvent({ token, role, slug: eventSlug }))
        .then(() => {
          console.log('Event fetched');
        })
        .catch((error: unknown) => {
          console.error('Failed to load event:', error);
        });
    }
  }, [dispatch, token, role, eventSlug]);

  // Replace the useEffect for tab management with the custom hook
  useNavigationTab(
    event?.name
      ? {
          section: 'discover/event-deployments',
          label: event.name,
          href: `/event-deployments/${event.slug}`,
          status: 'loaded',
        }
      : null,
    [event?.name, event?.slug],
  );

  if (!event) {
    return <NoMatchEvents />;
  }

  const formattedStartDate = dayjs(event.start_datetime).format(dateFormat);
  const formattedStartDateTime = dayjs(event.start_datetime).format(dateTimeFormat);
  const formattedEndDateTime = dayjs(event.end_datetime).format(dateTimeFormat);
  const formattedDecommissionDateTime = dayjs(event.decommission_datetime).format(dateTimeFormat);
  const formattedBuildDateTime = dayjs(event.build_datetime).format(dateTimeFormat);
  const poolStatus = event.account_pool?.status ?? 'No status available';
  const totalReadyAccounts = event.account_pool?.total_ready_child_accounts ?? 0;
  const allocatedAccounts = event.account_pool?.total_allocated_child_accounts ?? 0;
  const instructors = event.attendees ? event.attendees.filter((attendee) => attendee.role === 'instructor') : [];
  const allocatedPercentage = totalReadyAccounts === 0 ? 0 : (allocatedAccounts / totalReadyAccounts) * 100;

  return (
    <div className="relative" key={event.slug}>
      <main>
        <header className="relative isolate pt-4 pb-2 bg-dataops-primary-light-blue/20">
          <PageCenteredLayout>
            <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="mx-auto flex flex-col items-start text-left gap-y-1 lg:mx-0 lg:max-w-none">
                <h1 className="text-xl font-bold text-dataops-primary-dark-blue">{event.name}</h1>
                <p className="text-base text-gray-600">{formattedStartDate}</p>
                <p className="text-sm text-gray-500">{event.location}</p>
              </div>
            </div>
            <div id="event-tabs" className="flex absolute bottom-0 h-10 overflow-hidden">
              <Button
                size="large"
                intent="custom"
                className="bg-white text-dataops-primary-dark-blue rounded-none flex-col top-1"
                disabled
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <ListBulletIcon className="h-4 w-4" />
                  <span>Detail</span>
                </div>
              </Button>
              <Button
                size="large"
                intent="custom"
                className="transition-all bg-dataops-primary-light-blue/20 hover:bg-dataops-primary-light-blue/30 text-dataops-primary-dark-blue rounded-none flex-col top-1 hover:top-0"
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}/insights`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <ChartBarSquareIcon className="h-4 w-4" />
                  <span>Insights</span>
                </div>
              </Button>
              <Button
                size="large"
                intent="custom"
                className="transition-all bg-dataops-primary-light-blue/20 hover:bg-dataops-primary-light-blue/30 text-dataops-primary-dark-blue rounded-none flex-col top-1 hover:top-0"
                onClick={() => {
                  navigate(`/event-deployments/${event.slug}/accounts`);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <CircleStackIcon className="h-4 w-4" />
                  <span>Accounts</span>
                </div>
              </Button>
            </div>
          </PageCenteredLayout>
        </header>

        <PageCenteredLayout>
          <Grid3Layout>
            <SectionLayout label="Approval Status" className="md:col-span-2">
              {event.approval_status === 'PENDING' && (
                <div className="flex items-center justify-between">
                  <p className="text-dataops-primary-dark-blue">Pending Approval</p>
                  {canPublish && (
                    <div className="flex gap-4">
                      <div className="flex gap-4">
                        <ApproveButton eventSlug={event.slug} size="mediumPlus" />
                        <RejectButton eventSlug={event.slug} size="mediumPlus" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {event.approval_status === 'APPROVED' && (
                <p className="text-dataops-primary-dark-blue">
                  Approved on {event.approval_date && dayjs(event.approval_date).format(dateFormat)}
                </p>
              )}
              {event.approval_status === 'REJECTED' && (
                <p className="text-dataops-primary-dark-blue">Rejected: {event.rejection_reason}</p>
              )}
            </SectionLayout>
            <SectionLayout label="Actions" className="bg-dataops-supporting-gray/40 md:col-span-1 md:col-start-3">
              <div className="space-y-1">
                <div className="flex flex-col">
                  <Button
                    size="large"
                    intent="light"
                    onClick={() => {
                      window.open(`${dataopsliveBaseUrl}/${event.project}`, '_blank', 'noopener,noreferrer');
                    }}
                    className="w-full"
                  >
                    <WrenchScrewdriverIcon className="w-4 h-4 mr-1" /> View Configuration Project
                  </Button>
                </div>
                <div className="flex flex-col">
                  <Button
                    size="large"
                    intent="light"
                    onClick={() => {
                      window.open(
                        `${eventGoApplicationBaseUrl}/${event.slug}/instructions`,
                        '_blank',
                        'noopener,noreferrer',
                      );
                    }}
                    className="w-full"
                  >
                    <InformationCircleIcon className="w-4 h-4 mr-1" /> Launch Go App and claim account
                  </Button>
                </div>
              </div>
            </SectionLayout>
            <SectionLayout label="Event Review" className="md:col-span-2">
              <div className="grid grid-cols-4 gap-1 text-sm/6">
                <div className="col-span-1">Requested By:</div>
                <div className="col-span-3">{event.requestor}</div>
                <div className="col-span-1">Organization Account:</div>
                <div className="col-span-3">{event.organization_account}</div>
                <div className="col-span-1">Account Pool Size:</div>
                <div className="col-span-3">
                  {event.account_pool?.pool_size ?? event.initial_pool_size ?? 'No size available'}
                </div>
                <div className="col-span-1">Event Duration:</div>
                <div className="col-span-3">
                  {formattedStartDateTime} to {formattedEndDateTime}
                </div>
                <div className="col-span-1">Accounts Duration:</div>
                <div className="col-span-3">
                  {formattedBuildDateTime} to {formattedDecommissionDateTime}
                </div>
                <div className="col-span-1">Configuration Project:</div>
                <div className="col-span-3">
                  <a
                    href={`${dataopsliveBaseUrl}/${event.project}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-1 text-blue-600 hover:underline"
                  >
                    {event.project}
                  </a>
                </div>
                <div className="col-span-1">Account Region:</div>
                <div className="col-span-3">{event.snowflake_account_region_group}</div>
                <div className="col-span-1">Account Edition:</div>
                <div className="col-span-3">{event.snowflake_account_edition}</div>
              </div>
            </SectionLayout>
            <SectionLayout
              label="Manage Instructors"
              className="md:col-span-1"
              description="Instructors can be added so that they can see this page."
            >
              <div className="flex flex-col h-full">
                <AddInstructorButton eventSlug={event.slug} size="medium" className="" />
                <div className="space-y-2 mt-4">
                  {instructors.map((instructor, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <UserIcon className="h-4 w-4 text-dataops-primary-dark-blue" />
                      <span className="text-dataops-primary-dark-blue text-xs">{instructor.email}</span>
                    </div>
                  ))}
                </div>
              </div>
            </SectionLayout>
            <SectionLayout label="Event Detail" className="md:col-span-2">
              <div className="grid grid-cols-4 gap-1 text-sm/6">
                <div className="col-span-1">Event Name:</div>
                <div className="col-span-3">{event.name}</div>
                <div className="col-span-1">Event Slug:</div>
                <div className="col-span-3">
                  <a
                    href={`${eventGoApplicationBaseUrl}/${event.slug}/instructions`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {event.slug}
                  </a>
                </div>
                <div className="col-span-1">Location:</div>
                <div className="col-span-3">{event.location}</div>
                <div className="col-span-1">Build Date:</div>
                <div className="col-span-3">{formattedBuildDateTime}</div>
                <div className="col-span-1">Start Date:</div>
                <div className="col-span-3">{formattedStartDateTime}</div>
                <div className="col-span-1">End Date:</div>
                <div className="col-span-3">{formattedEndDateTime}</div>
                <div className="col-span-1">Decommission Date:</div>
                <div className="col-span-3">{formattedDecommissionDateTime}</div>
              </div>
            </SectionLayout>
            <SectionLayout label="Account Pool" className="md:col-span-1">
              <div className="flex flex-col h-full">
                <EventAccountPoolAllocationBar allocatedPercentage={allocatedPercentage} />
                <p className="text-dataops-primary-dark-blue text-sm/6">
                  Status: <PoolStatus status={poolStatus} />
                </p>
                <p className="text-dataops-primary-dark-blue text-sm/6">Accounts Ready: {totalReadyAccounts}</p>
                <p className="text-dataops-primary-dark-blu text-sm/6">Accounts Allocated: {allocatedAccounts}</p>
              </div>
            </SectionLayout>
          </Grid3Layout>

          {/* Instructions Section */}
          <div className="mt-8">
            <SectionLayout label="Instructions" className="bg-dataops-supporting-gray/40">
              <div className="border p-4 rounded-md bg-white min-h-[16rem] overflow-auto">
                <MarkdownEditor
                  value={event.instructions || 'No instructions available.'}
                  eventName={event.name}
                  onChange={() => {}}
                  hideEditButton={true}
                  hidePreviewButton={true}
                  initialPreviewMode={true}
                />
              </div>
            </SectionLayout>
          </div>
        </PageCenteredLayout>
      </main>
    </div>
  );
}
