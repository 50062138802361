import React, { useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { type IChildAccount } from '../../interfaces/IChildAccount';
import { useToken } from '../../hooks/useToken';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { fetchEvent } from '../../reducers/eventsSlice';
import { getConfig } from '../../config/config-helper';

interface ReconfigureAccountButtonProps {
  account?: IChildAccount;
  accountIds?: number[];
  eventSlug?: string;
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'mediumPlus';
  label?: string;
  intent?: 'primary' | 'secondary' | 'danger' | 'custom';
}

const ReconfigureAccountButton: React.FC<ReconfigureAccountButtonProps> = ({
  account,
  accountIds,
  eventSlug,
  className = '',
  size = 'small',
  label = 'Re-configure Account',
  intent = 'secondary',
}) => {
  const [isReconfiguring, setIsReconfiguring] = useState(false);
  const token = useToken();
  const { canPublish } = useCurrentUser();
  const role = canPublish ? 'admin' : 'consumer';
  const { eventSlug: eventSlugFromParams } = useParams();
  const finalEventSlug = eventSlug ?? eventSlugFromParams;
  const dispatch = useAppDispatch();

  const getAccountIds = (): number[] => {
    if (accountIds && accountIds.length > 0) {
      return accountIds;
    } else if (account?.id) {
      return [account.id];
    }
    return [];
  };

  const handleReconfigureAccount = async () => {
    if (!finalEventSlug) {
      console.error('Event slug is required');
      return;
    }

    const { dataopsAdminAppApiEndpoint } = getConfig();
    const url = `${dataopsAdminAppApiEndpoint}/event_management/${finalEventSlug}/rerun_configure_pipeline`;
    const ids = getAccountIds();
    if (ids.length === 0 || !canPublish) return;

    setIsReconfiguring(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'SSC-Role': role,
        },
        body: JSON.stringify({ child_account_ids: ids }),
      });

      if (!response.ok) {
        throw new Error('Failed to rerun configure pipeline');
      }

      await dispatch(fetchEvent({ token, role, slug: finalEventSlug }));
    } catch (error) {
      console.error('Error reconfiguring account:', error);
    } finally {
      setIsReconfiguring(false);
    }
  };

  const isDisabled = () => {
    if (accountIds && accountIds.length > 0) {
      return !canPublish;
    } else if (account) {
      return !canPublish;
    }
    return true;
  };

  return (
    <Button
      size={size}
      intent={intent}
      className={`flex items-center gap-1 ${className}`}
      onClick={handleReconfigureAccount}
      loading={isReconfiguring}
      disabled={isDisabled()}
    >
      <ArrowPathIcon className={size === 'small' ? 'h-3 w-3 mr-1' : 'h-4 w-4 mr-1'} />
      <span>{label}</span>
    </Button>
  );
};

export default ReconfigureAccountButton;
