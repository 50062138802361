import React from 'react';
import GridList, { type IAction } from '../controls/GridList';
import { BoltIcon, BuildingLibraryIcon, GlobeAltIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ImportColumnLayout from '../layout/ImportColumnLayout';

export default function NewSolutionPage() {
  const navigate = useNavigate();
  const actions: IAction[] = [
    {
      title: 'New from Git repository',
      desc: 'Use your existing git repository to add a listing entry to Solution Central.',
      onClick: (action, actionIdx) => {
        navigate('/new/from-git');
      },
      tier: 4,
      kind: 'Hyperlink',
      icon: GlobeAltIcon,
      iconForeground: 'dataops-secondary-light-green',
      iconBackground: 'dataops-secondary-light-green/10',
      eventPayload: {
        name: 'new-git-import-start',
      },
    },
    {
      title: 'New from Snowflake Notebook',
      desc: 'Use your existing Snowflake notebook to add a listing entry to Solution Central.',
      onClick: (action, actionIdx) => {
        navigate('/new/from-notebook');
      },
      tier: 3,
      kind: 'Solution',
      icon: PencilSquareIcon,
      iconForeground: 'purple-700',
      iconBackground: 'purple-700',
      eventPayload: {
        name: 'new-snowflake-notebook-start',
      },
    },
    {
      title: 'New from SSC Framework Template',
      desc: 'Use the Solution Central Framework template to start a new listing entry.',
      onClick: (action, actionIdx) => {
        navigate('/new/from-framework');
      },
      tier: 1,
      kind: 'Solution',
      icon: BuildingLibraryIcon,
      iconForeground: 'sky-700',
      iconBackground: 'sky-50',
      eventPayload: {
        name: 'new-framework-template-start',
      },
    },
    {
      title: 'New Event',
      desc: 'Create a generic event with a default event solution.',
      onClick: (action, actionIdx) => {
        navigate('/new/event');
      },
      kind: 'Event',
      icon: BoltIcon,
      iconForeground: 'dataops-primary-light-blue',
      iconBackground: 'dataops-primary-light-blue',
      eventPayload: {
        name: 'new-event-start',
      },
    },
  ];

  // const NUMBER_OF_STEPS = pages.length;

  return (
    <ImportColumnLayout>
      <GridList actions={actions} />
    </ImportColumnLayout>
  );
}
