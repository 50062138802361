import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { type AxiosError } from 'axios';
import { type RootState } from '../store';
import { type IVerticalNavLinkTab } from '../components/nav/VerticalNav';
import { getConfig } from '../config/config-helper';

export interface IAttendee {
  name: string;
  email: string;
  role: 'admin' | 'consumer' | 'instructor';
}

export interface IAccountPool {
  id: number;
  status: string;
  pool_size: number;
  total_child_accounts: number;
  total_ready_child_accounts: number;
  total_allocated_child_accounts: number;
}

export interface IEventMilestone {
  id: number;
  title: string;
  attendees_reached: number;
  condition_sql: string;
  event: number;
  sequence_order: number;
  expected_by_timestamp: string;
  expected_by_minute: number;
}

export interface IEvent {
  id: number;
  slug: string;
  name: string;
  location: string;
  build_datetime: string;
  start_datetime: string;
  end_datetime: string;
  decommission_datetime: string;
  instructions: string;
  organization_account: string;
  initial_pool_size: number;
  requestor: string | null;
  project: any;
  snowflake_account_edition: string;
  snowflake_account_region_group: string;
  approval_status: 'PENDING' | 'APPROVED' | 'REJECTED';
  approval_date: string | null;
  rejection_reason: string;
  attendees: IAttendee[];
  account_pool: IAccountPool | null;
  milestones: IEventMilestone[];
  eventAttendees: IEventAttendee[];
}

export interface IMilestoneProgress {
  attendee_id: number;
  event_milestone_id: number;
  achieved_by: string;
}

export interface IEventAttendee {
  email: string;
  name: string;
  account_identifier: string | '';
  account_url: string | '';
  allocated_at: string | '';
  milestones: IMilestoneProgress[];
}

interface IEventsState {
  events: IEvent[];
  tabs: IVerticalNavLinkTab[];
  requestStatus: 'idle' | 'loading' | 'failed' | 'succeeded';
}

const initialState: IEventsState = {
  events: [],
  tabs: [],
  requestStatus: 'idle',
};

export const fetchEvents = createAsyncThunk(
  'events/fetch',
  async ({ token, role }: { token: string; role: 'admin' | 'consumer' }): Promise<IEvent[]> => {
    const { dataopsAdminAppApiEndpoint, snowflakeOrganizationAccounts } = getConfig();

    const organizationAccount =
      snowflakeOrganizationAccounts && snowflakeOrganizationAccounts.length > 0
        ? snowflakeOrganizationAccounts[0]
        : undefined;

    const url = `${dataopsAdminAppApiEndpoint}/event_management/events`;
    console.log('Fetching events:', url);

    try {
      if (!token) throw new Error('Token not provided');

      const params: Record<string, string> = {};
      if (organizationAccount) {
        params.organization_account = organizationAccount;
      }

      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${token}`, 'SSC-Role': role },
        params,
      });

      return response.data;
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data || 'Unknown error';
      console.error('Error fetching events:', errorMessage);
      throw error;
    }
  },
);

export const fetchEvent = createAsyncThunk(
  'events/fetchOne',
  async ({ token, role, slug }: { token: string; role: string; slug: string }): Promise<IEvent> => {
    const { dataopsAdminAppApiEndpoint } = getConfig();
    const url = `${dataopsAdminAppApiEndpoint}/event_management/events/${slug}`;

    try {
      if (!token) throw new Error('Token not provided');

      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${token}`, 'SSC-Role': role },
      });

      return response.data;
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data || 'Unknown error';
      console.error('Error fetching event:', errorMessage);
      throw error;
    }
  },
);

export const fetchEventAttendees = createAsyncThunk(
  'events/fetchEventAttendees',
  async ({ token, role, slug }: { token: string; role: string; slug: string }): Promise<IEventAttendee[]> => {
    const { dataopsAdminAppApiEndpoint } = getConfig();
    const url = `${dataopsAdminAppApiEndpoint}/event_management/${slug}/insights`;

    try {
      if (!token) throw new Error('Token not provided');

      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${token}`, 'SSC-Role': role },
      });

      return response.data;
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data || 'Unknown error';
      console.error('Error fetching event insights:', errorMessage);
      throw error;
    }
  },
);

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state) => {
      state.requestStatus = 'loading';
    });
    builder.addCase(fetchEvents.rejected, (state) => {
      state.requestStatus = 'failed';
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      // Get all event slugs from the payload
      const payloadSlugs = action.payload.map((event) => event.slug);

      // Filter out events that are no longer in the payload (deleted events)
      state.events = state.events.filter((event) => payloadSlugs.includes(event.slug));

      // Update existing events or add new ones
      action.payload.forEach((newEvent) => {
        const existingEvent = state.events.find((e) => e.slug === newEvent.slug);
        if (existingEvent) {
          Object.assign(existingEvent, newEvent);
        } else {
          state.events.push(newEvent);
        }
      });

      state.requestStatus = 'succeeded';
    });
    builder.addCase(fetchEvent.pending, (state) => {
      state.requestStatus = 'loading';
    });
    builder.addCase(fetchEvent.rejected, (state) => {
      state.requestStatus = 'failed';
    });
    builder.addCase(fetchEvent.fulfilled, (state, action) => {
      const existingEvent = state.events.find((e) => e.slug === action.payload.slug);
      if (existingEvent) {
        Object.assign(existingEvent, action.payload);
      } else {
        state.events.push(action.payload);
      }
      state.requestStatus = 'succeeded';
    });
    builder.addCase(fetchEventAttendees.pending, (state) => {
      state.requestStatus = 'loading';
    });
    builder.addCase(fetchEventAttendees.rejected, (state) => {
      state.requestStatus = 'failed';
    });
    builder.addCase(fetchEventAttendees.fulfilled, (state, action) => {
      const event = state.events.find((e) => e.slug === action.meta.arg.slug);
      if (event) {
        event.eventAttendees = action.payload;
      }
      state.requestStatus = 'succeeded';
    });
  },
});

// Selectors
export const selectEvents = (state: RootState) => state.events.events;
export const selectRequestStatus = (state: { events: IEventsState }) => state.events.requestStatus;
export const selectEventBySlug = (state: RootState, slug: string) =>
  state.events.events.find((event) => event.slug === slug);

export default eventsSlice.reducer;
