import React, { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react';
import VerticalNav, { type IVerticalNavLink, type IVerticalNavSection } from '../nav/VerticalNav';
import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
  RocketLaunchIcon,
  XMarkIcon,
  LifebuoyIcon,
  PencilSquareIcon,
  BoltIcon,
} from '@heroicons/react/24/outline';
import { SmallScreenTopBarNav } from '../nav/SmallScreenTopBarNav';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { type IBroadcast } from '../../interfaces/IBroadcast';
import broadcastData from '../../broadcasts/data.json';
import BroadcastBanners from '../notification/BroadcastBanners';
import BroadcastBannerRefreshAuth from '../notification/BroadcastBannerRefreshAuth';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { removeTab, selectTabs } from '../../reducers/accountSlice';
import { classNames } from '../../utilities/styleUtils';

export default function SidebarLayout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Add this to determine current path
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const broadcasts: IBroadcast[] = broadcastData;
  const tabs = useAppSelector(selectTabs);

  const closableTabs = tabs.map((tab) => {
    return {
      ...tab,
      closeCallback: () => {
        // Check if the tab being closed is the current active tab
        const isActiveTab = location.pathname === tab.href;

        // First remove the tab from the state
        dispatch(removeTab(tab));

        // Only navigate if closing the active tab
        if (isActiveTab) {
          switch (tab.section) {
            case 'discover/solutions':
              navigate('/solutions');
              break;
            case 'discover/deployments':
              navigate('/deployments');
              break;
            case 'discover/event-deployments':
              navigate('/event-deployments');
              break;
          }
        }
      },
    };
  });

  const discoverNavItems: IVerticalNavLink[] = [
    // { name: 'Dashboard', href: '/', icon: HomeIcon },
    {
      label: 'Solutions',
      href: '/solutions',
      icon: MagnifyingGlassIcon,
      onClickEventName: 'navigation-solutions-clicked',
      tabs: closableTabs.filter((tab) => tab.section === 'discover/solutions'),
    },
    {
      label: 'Deployments',
      href: '/deployments',
      icon: RocketLaunchIcon,
      onClickEventName: 'navigation-deployments-clicked',
      tabs: closableTabs.filter((tab) => tab.section === 'discover/deployments'),
    },
    {
      label: 'Events',
      href: '/event-deployments',
      icon: BoltIcon,
      onClickEventName: 'navigation-events-clicked',
      tabs: closableTabs.filter((tab) => tab.section === 'discover/event-deployments'),
    },
  ];

  const managerNavItems: IVerticalNavLink[] = [
    { label: 'New', href: '/new', icon: PlusCircleIcon, onClickEventName: 'navigation-new-clicked' },
    {
      label: 'Drafts',
      href: '/manage/solution-drafts',
      icon: PencilSquareIcon,
      onClickEventName: 'navigation-drafts-clicked',
    },
  ];

  const supportNavItems: IVerticalNavLink[] = [
    { label: 'Help + Docs', href: '/support', icon: LifebuoyIcon, onClickEventName: 'navigation-help-clicked' },
  ];

  const navSections: IVerticalNavSection[] = [
    { items: discoverNavItems },
    { title: 'Solution Manager', items: managerNavItems },
    { title: 'Support', items: supportNavItems },
  ];

  return (
    <>
      <StageBanner />
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-64 flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <VerticalNav key={'collapsible-sidebar'} sections={navSections} />
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-64 lg:flex-col">
          <VerticalNav key={'desktop-sidebar'} sections={navSections} />
        </div>

        {/* Small screen top nav */}
        <SmallScreenTopBarNav setSidebarOpen={setSidebarOpen} />

        <main className="lg:pl-64">
          <div>
            <BroadcastBannerRefreshAuth />
            <BroadcastBanners broadcastData={broadcasts} />
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}

function StageBanner() {
  const reactEnv = (process.env.REACT_APP_ENV ?? 'dev') as 'dev' | 'qa' | 'preview' | 'production';
  const stageMap = {
    dev: { label: 'Local', classes: 'bg-blue-300' },
    qa: { label: 'QA', classes: 'bg-yellow-300' },
    preview: { label: 'Preview', classes: 'bg-purple-300' },
    production: { label: 'Production', classes: 'hidden' },
  };

  return (
    <div aria-hidden className={`z-50 fixed top-0 left-0 w-full h-1 text-right ${stageMap[reactEnv].classes}`}>
      <span aria-hidden className={classNames('px-1 py-0.5 rounded-b-sm text-xs', stageMap[reactEnv].classes)}>
        {stageMap[reactEnv].label}
      </span>
    </div>
  );
}
