import React from 'react';
import { type IEvent } from '../../reducers/eventsSlice';
import { CheckCircleIcon, ClockIcon, XCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Tag from '../common/Tag';
import { classNames } from '../../utilities/styleUtils';

interface EventItemProps {
  event: IEvent;
}

const calculateAccountsAllocatedPercentage = (childAccounts: number, poolSize: number): number => {
  if (poolSize === 0) return 0;
  return (childAccounts / poolSize) * 100;
};

const isDecommissioned = (decommissionDate: string): boolean => {
  return new Date(decommissionDate) < new Date();
};

export default function EventItem({ event }: EventItemProps) {
  const accountPool = event.account_pool;
  const totalReadyAccounts = event.account_pool?.total_ready_child_accounts ?? 0;
  const childAccounts = accountPool?.total_allocated_child_accounts ?? 0;
  const accountsAllocatedPercentage = calculateAccountsAllocatedPercentage(childAccounts, totalReadyAccounts);
  const isEventDecommissioned = isDecommissioned(event.decommission_datetime);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'APPROVED':
        return (
          <>
            <Tag color={'gray'}>
              <CheckCircleIcon className="mr-1 w-5 h-5 text-dataops-secondary-dark-green" />
              Approved
            </Tag>
          </>
        );

      case 'PENDING':
        return (
          <>
            <Tag color={'orange'}>
              <ClockIcon className="mr-1 w-5 h-5 text-dataops-secondary-orange" />
              Waiting for approval
            </Tag>
          </>
        );
      case 'REJECTED':
        return (
          <>
            <Tag color={'gray'}>
              <XCircleIcon className="mr-1 w-5 h-5 text-dataops-secondary-red" />
              Rejected
            </Tag>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={classNames(
        `relative grid grid-cols-11 gap-x-4 px-2 py-2 shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50`,
        isEventDecommissioned ? 'bg-gray-100 border-l-4' : 'bg-white border-l-4 border-dataops-primary-light-blue',
      )}
    >
      <div className="col-span-3 flex items-center justify-left gap-x-4 text-sm">
        <div className="col-span-2 flex flex-col items-left justify-left text-sm">
          <p className="text-sm font-semibold text-gray-900">{event.name}</p>
          <p className="text-xs text-gray-500">{event.slug}</p>
        </div>
      </div>
      <div className="col-span-2 flex items-center justify-left gap-x-4 text-sm">
        {getStatusIcon(event.approval_status)}
      </div>
      <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">
        {new Date(event.build_datetime).toLocaleDateString()}
      </div>
      <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">
        {new Date(event.decommission_datetime).toLocaleDateString()}
      </div>
      <div className="hidden col-span-2 sm:flex items-center justify-left gap-x-4 text-sm">
        <div className="overflow-hidden w-48 h-2 rounded-full bg-dataops-supporting-black/10">
          <div
            style={{ width: `${accountsAllocatedPercentage}%` }}
            className={`h-2 rounded-full transition-all duration-300 bg-dataops-primary-dark-blue`}
          />
        </div>
        <div className="text-sm">
          <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
    </div>
  );
}
