import React from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utilities/styleUtils';

export interface SelectOption {
  id: string;
  name: string;
  description?: string;
  icon?: string;
  path?: string;
}

interface InputSelectProps {
  label?: string;
  optional?: boolean;
  help?: React.ReactNode;
  icon?: React.ElementType;
  error?: string;
  loading?: boolean;
  options: SelectOption[];
  value?: SelectOption | null;
  onChange: (value: SelectOption) => void;
  id?: string;
  placeholder?: string;
}

export function InputSelect({
  label = undefined,
  optional = false,
  icon = undefined,
  help = undefined,
  error = undefined,
  loading = false,
  options,
  value,
  onChange,
  id = 'input',
  placeholder = 'Select an option',
}: InputSelectProps) {
  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              'mb-2 block text-sm font-medium leading-6',
              loading ? 'text-gray-400' : 'text-gray-900',
            )}
          >
            {label}
          </label>
        )}
        {optional && (
          <span id={`${id}-optional`} className="mb-2 text-sm leading-6 text-gray-500 cursor-default">
            Optional
          </span>
        )}
      </div>
      <Listbox value={value} onChange={onChange} disabled={loading}>
        <div className="relative">
          <ListboxButton
            className={classNames(
              'relative h-[33px] w-full border bg-transparent px-4 text-left text-gray-900 sm:text-sm',
              'focus:outline focus:outline-4 transition-all ease-in-out',
              icon ? 'pl-11' : '',
              help ?? error ? 'rounded-t-md' : 'rounded-md',
              error
                ? 'border-red-300 focus:outline-red-200'
                : 'border-gray-200 focus:outline-dataops-primary-light-blue/20',
              loading ? 'bg-gray-50 text-gray-400 cursor-wait' : '',
            )}
          >
            <span className="block truncate">{value?.name ?? placeholder}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>
          {icon &&
            React.createElement(icon, {
              className: classNames(
                'pointer-events-none absolute left-3 top-2 h-5 w-5',
                loading ? 'text-gray-300' : 'text-gray-500',
              ),
            })}
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option) => (
                <ListboxOption
                  key={option.id}
                  className={({ selected }) =>
                    classNames(
                      'relative cursor-pointer select-none py-2 pl-3 pr-9 transition-colors duration-100',
                      selected ? 'bg-dataops-primary-light-blue/5' : '',
                      'hover:bg-dataops-primary-light-blue/10',
                    )
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <div className="flex items-start">
                      {option.icon && <img src={option.icon} alt="" className="h-5 w-5 mr-2 mt-0.5 rounded-full" />}
                      <div>
                        <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>
                          {option.name}
                        </span>
                        {option.path && <span className="block text-xs text-gray-500">{option.path}</span>}
                        {option.description && (
                          <span className="mt-1 block text-xs text-gray-500">{option.description}</span>
                        )}
                      </div>
                    </div>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
          {loading && (
            <div className="absolute inset-y-0 right-8 flex items-center">
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-gray-400" />
            </div>
          )}
        </div>
      </Listbox>
      <div>
        {error && <div className="w-full px-4 py-2 text-sm text-red-600 bg-red-50">{error}</div>}
        {help && (
          <div className="w-full px-4 py-2 leading-5 space-y-2 text-sm text-gray-700 bg-dataops-primary-dark-blue/5 rounded-b-md">
            {help}
          </div>
        )}
      </div>
    </div>
  );
}
