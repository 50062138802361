import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { getConfig } from '../../config/config-helper';
import { useAppDispatch } from '../../hooks';
import ProjectIcon from '../common/ProjectIcon';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import { useDeployment } from '../../hooks/useDeployment';
import { getEnvVariableValue } from '../../utilities/parsers';
import LoadingIndicator from '../common/LoadingIndicator';
import {
  fetchDeployment,
  fetchDeploymentUpdates,
  type IDeployment,
  selectRequestStatus,
} from '../../reducers/deploymentsSlice';
import Button from '../controls/Button';
import UpdateButton from '../controls/UpdateButton';
import HomepageLaunchButton from '../controls/HomepageLaunchButton';
import SnowsightButton from '../controls/SnowsightButton';
import PipelineGraphic from '../project/PipelineGraphic';
import Tag from '../common/Tag';
import { classNames } from '../../utilities/styleUtils';
import DeleteButton from '../controls/DeleteButton';
import { useToken } from '../../hooks/useToken';
import ValidateButton from '../controls/ValidateButton';
import CopyUrlButton from '../controls/CopyUrlButton';
import ShareWithCustomerButton from '../controls/ShareWithCustomerButton';
import { useSolution } from '../../hooks/useSolution';
import SectionLayout from '../layout/SectionLayout';
import Grid3Layout from '../layout/Grid3Layout';
import DataOpsInfinity from '../../assets/images/icons/DataOps_darkblue_icon.svg';
import { useNavigationTab } from '../../hooks/useNavigationTab';

export default function DeploymentPage() {
  const { dataopsliveBaseUrl } = getConfig();

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const token = useToken();
  const { deployment } = useDeployment(id);
  const solutionName = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_NAME');
  const solutionId = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID');
  const { solution } = useSolution(solutionId);
  const updateAvailable = deployment.commitsBehind && deployment.commitsBehind.length > 0;
  const fullPath = `/deployments/${id ?? ''}`;
  const deploymentDolUrl = `${dataopsliveBaseUrl}/${deployment.fullPath ?? ''}`;
  const deepLinkToPipelines = `${deployment.webUrl}/pipelines`;
  const avatarUrl = deployment?.avatarUrl ?? undefined;

  function dispatchDeploymentUpdates() {
    dispatch(
      fetchDeploymentUpdates({
        token,
        deployment,
      }),
    ).catch((err) => err);
  }

  useNavigationTab(
    deployment.customerName && solutionName
      ? {
          section: 'discover/deployments',
          label: `${deployment.customerName} / ${solutionName}`,
          href: fullPath,
          status: deployment.requestExtraStatus === 'loading' ? 'loading' : 'loaded',
        }
      : null,
    [deployment.customerName, solutionName, deployment.requestExtraStatus],
  );

  // Polling effect when visible
  useEffect(() => {
    if (deployment.fullPath === '') {
      return;
    }
    dispatchDeploymentUpdates();
    const intervalId = setInterval(() => {
      dispatchDeploymentUpdates();
    }, 10 * 1000); // Poll every 10 seconds (10000 milliseconds)
    return () => {
      clearTimeout(intervalId);
    };
  }, [deployment.fullPath]);

  useEffect(() => {
    if (token === '' || id === undefined) {
      return;
    }
    dispatch(fetchDeployment({ token, id })).catch((err) => {
      console.log('Fetch deployment error:', err);
    });
  }, [token, id]);

  return (
    <div className="relative" key={deployment.id}>
      <LoadingIndicator selectRequestStatus={selectRequestStatus} />
      {/* Experimenting with handling failed updates */}
      {/* {deployment.requestExtraStatus === 'failed' && (
        <div className="absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full backdrop-blur-lg space-y-6">
          <div className="text-lg font-bold text-dataops-primary-light-blue">404</div>
          <div className="text-xl">Could not get this deployment</div>
          <Link to="/deployments" className="text-dataops-secondary-dark-blue hover:underline">
            Go back to deployments
          </Link>
        </div>
      )} */}
      <main>
        <header className="relative isolate pt-10">
          <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
            <div className="absolute left-16 top-full -mt-1 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
              <div
                style={{
                  clipPath:
                    'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 0.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                }}
                className={classNames(
                  'aspect-[1154/678] w-[62.125rem] bg-gradient-to-br',
                  'from-dataops-primary-light-blue to-dataops-secondary-dark-blue',
                )}
              />
            </div>
            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
          </div>

          <PageCenteredLayout>
            <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex items-center gap-x-6">
                <ProjectIcon iconSrc={avatarUrl} />
                <h1>
                  <div className="mt-1 flex space-x-2">
                    <span className="text-base font-semibold text-dataops-primary-dark-blue">
                      {deployment.customerName}
                    </span>{' '}
                    <span>/</span>
                    <Link to={`/solutions/${solutionId}`} className="text-dataops-primary-dark-blue/70 hover:underline">
                      <span>{solutionName}</span>
                    </Link>
                    {updateAvailable && <Tag color={'orange'}>Update available</Tag>}
                  </div>
                  <a
                    href={deploymentDolUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-1 text-sm/6 text-dataops-primary-dark-blue/80 hover:underline"
                  >
                    <img src={DataOpsInfinity} className="w-4 h-4 mt-0.5" />
                    <span>{deployment.fullPath}</span>
                  </a>
                </h1>
              </div>
              <div className="flex items-center sm:gap-x-1">
                <CopyUrlButton view="deployment" />
                <ShareWithCustomerButton solution={solution} deployment={deployment} />
              </div>
            </div>
          </PageCenteredLayout>
        </header>

        <PageCenteredLayout>
          <Grid3Layout>
            <SectionLayout label="Actions" className="bg-dataops-supporting-gray/40 md:col-span-1 md:col-start-3">
              <div className="space-y-1">
                <UpdateButton deployment={deployment} size="large" />
                <HomepageLaunchButton deployment={deployment} size="large" />
                <ValidateButton deployment={deployment} size="large" />
                <SnowsightButton deployment={deployment} size="large" />
                <DeleteButton deployment={deployment} />
              </div>
            </SectionLayout>
            <SectionLayout
              label="Status"
              description="Deployment status shows the progress of the deployment into your Snowflake account."
              className="md:col-span-1 md:row-start-1"
            >
              <div className="flex flex-col h-full space-y-4">
                {deployment.pipelines ? (
                  <div className="flex justify-center w-full max-w-72 h-20 rounded-md outline outline-dataops-primary-dark-blue/20">
                    <PipelineGraphic pipeline={deployment.pipelines[0]} deepLinkHref={deepLinkToPipelines} stayOpen />
                  </div>
                ) : (
                  <div className="flex justify-center w-full max-w-72 h-20 rounded-md outline outline-dataops-primary-dark-blue/20 bg-shine"></div>
                )}
              </div>
            </SectionLayout>

            <SectionLayout
              label="Deployment service user"
              description="These are the Snowflake account credentials of the service user created by DataOps.live."
              className="md:col-span-1 md:row-start-1"
            >
              <div className="flex flex-col h-full space-y-4">
                <div>
                  <DeploymentVariable deployment={deployment} varKey="DATAOPS_SOLE_ACCOUNT" />
                  <DeploymentVariable deployment={deployment} varKey="DATAOPS_SOLE_USERNAME" />
                  <DeploymentVariable deployment={deployment} varKey="DATAOPS_SOLE_PASSWORD" masked />
                </div>
              </div>
            </SectionLayout>
            <SectionLayout
              label="Configuration"
              description="Deployment configuration provided during setup."
              className="md:col-span-2 row-span-2"
            >
              <div className="flex flex-col h-full space-y-4">
                <div className="space-y-6">
                  <DeploymentConfig
                    deployment={deployment}
                    configKey="DATAOPS_CATALOG_CUSTOMER_NAME"
                    label="Customer name"
                  />
                  <DeploymentConfigLogo deployment={deployment} />
                  <DeploymentConfig
                    deployment={deployment}
                    configKey="DATAOPS_CATALOG_OPPORTUNITY_ID"
                    label="Salesforce ID"
                  />
                  <DeploymentVariable
                    deployment={deployment}
                    varKey="DATAOPS_CATALOG_SOLUTION_PREFIX"
                    label="Snowflake object prefix"
                  />
                </div>
              </div>
            </SectionLayout>
          </Grid3Layout>
        </PageCenteredLayout>
      </main>
    </div>
  );
}

function DeploymentConfig({
  deployment,
  configKey,
  label,
}: {
  deployment: IDeployment;
  configKey: string;
  label?: string;
}) {
  const configValue = getEnvVariableValue(deployment.ciVariables, configKey);
  return (
    <div className="space-y-1">
      <div className="text-xs text-dataops-primary-dark-blue/80">{label ?? configKey}</div>
      <div>{configValue !== '' ? configValue : 'Not available.'}</div>
    </div>
  );
}

function DeploymentConfigLogo({ deployment }: { deployment: IDeployment }) {
  const logoUrl = getEnvVariableValue(deployment.ciVariables, 'DATAOPS_CATALOG_CUSTOMER_LOGO_URL');
  // parse a URL safe URL back into a URL
  const decodedUrl = decodeURIComponent(logoUrl);
  return (
    <div className="space-y-1">
      <div className="text-xs text-dataops-primary-dark-blue/80">Customer logo</div>
      <div>
        {logoUrl !== '' ? (
          <img
            src={decodedUrl}
            alt="Customer logo"
            className="h-16 max-w-full p-1 rounded-md mr-2 outline outline-1 outline-dataops-supporting-gray"
          />
        ) : (
          'Not available.'
        )}
      </div>
    </div>
  );
}

function DeploymentVariable({
  deployment,
  varKey,
  masked = false,
  label,
}: {
  deployment: IDeployment;
  varKey: string;
  masked?: boolean;
  label?: string;
}) {
  const varValue = getEnvVariableValue(deployment.ciVariables, varKey);
  const [hidden, setHidden] = useState(masked);
  const textInputRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const toggleHidden = () => {
    setHidden(!hidden);
  };

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
  }
  const handleCopyClick = (copyText: string) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.log('Error while handling copy click', error);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="w-full text-xs text-dataops-primary-dark-blue/80">{label}</div>
      <div className="flex space-x-1">
        <input
          type={hidden ? 'password' : 'text'}
          value={varValue}
          ref={textInputRef}
          readOnly
          className={
            'flex-grow h-[33px] w-full border border-gray-200 bg-transparent pl-1 pr-4 text-gray-900 placeholder:text-dataops-primary-dark-blue/80 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out'
          }
        />
        {masked && (
          <Button
            className="h-[33px]"
            onClick={() => {
              toggleHidden();
            }}
            intent="light"
            size="small"
          >
            {hidden ? 'Reveal' : 'Conceal'}
          </Button>
        )}
        <Button
          onClick={() => {
            handleCopyClick(varValue);
          }}
          intent="light"
          size="small"
          className={classNames(isCopied ? 'bg-lime-300 hover:bg-lime-200' : 'bg-white', 'h-[33px]')}
        >
          Copy
        </Button>
      </div>
    </div>
  );
}
