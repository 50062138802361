import React from 'react';
import { classNames } from '../../utilities/styleUtils';

interface Props {
  children: React.ReactNode;
  extraClassName?: string;
}

export function PageCenteredLayout({ children, extraClassName = 'py-16' }: Props) {
  return <div className={classNames('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', extraClassName)}>{children}</div>;
}
