import React from 'react';
import Datepicker, { type DateValueType, type Configs } from 'react-tailwindcss-datepicker';
import { classNames } from '../../utilities/styleUtils';

interface IInputDatepicker extends React.InputHTMLAttributes<HTMLInputElement> {
  dateValue: DateValueType;
  setDateValue: (arg0: DateValueType) => void;
  showFooter?: boolean;
  showShortcuts?: boolean;
  useRange?: boolean;
  asSingle?: boolean;
  minDate?: Date;
  maxDate?: Date;
  configs?: Configs;
  dataTestid?: string;
  label?: string;
  optional?: boolean;
  help?: React.ReactNode;
  icon?: React.ElementType;
  error?: string;
}

/**
 *
 * https://react-tailwindcss-datepicker.vercel.app/
 *
 */
export default function InputDatepicker({
  // Input
  id,

  // Datepicker
  dateValue,
  setDateValue,
  showFooter = false,
  showShortcuts = false,
  useRange = false,
  asSingle = true,
  minDate = undefined,
  maxDate = undefined,
  configs = undefined,

  // DataOps.live Additions
  optional = false,
  dataTestid = undefined,
  label = undefined,
  help = undefined,
  icon = undefined,
  error = undefined,
}: IInputDatepicker) {
  const testId = dataTestid ?? id ?? 'datepicker';
  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id ?? 'input'} className="mb-2 block text-sm font-medium leading-6 text-gray-900">
            {label}
          </label>
        )}
        {optional && (
          <span id={`${id ?? 'input'}-optional`} className="mb-2 text-sm leading-6 text-gray-500 cursor-default">
            Optional
          </span>
        )}
      </div>
      <div className="relative">
        <Datepicker
          inputId={testId}
          containerClassName=""
          inputClassName={classNames(
            'h-[33px] w-full border border-gray-200 bg-transparent px-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out',
            icon ? 'pl-11' : '',
            help ? 'rounded-t-md' : 'rounded-md',
          )}
          toggleClassName="hidden"
          primaryColor={'blue'}
          asSingle={asSingle}
          useRange={useRange}
          minDate={minDate}
          maxDate={maxDate}
          showFooter={showFooter}
          showShortcuts={showShortcuts}
          value={dateValue}
          onChange={(newValue) => {
            setDateValue(newValue);
          }}
          configs={configs}
        />
        {icon &&
          React.createElement(icon, { className: 'pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500' })}
      </div>
      <div>
        {error && <div className="w-full px-4 py-2 text-sm text-red-600 bg-red-50">{error}</div>}
        {help && (
          <div className="w-full px-4 py-2 leading-5 space-y-2 text-sm text-gray-700 bg-dataops-primary-dark-blue/5 rounded-b-md">
            {help}
          </div>
        )}
      </div>
    </div>
  );
}
