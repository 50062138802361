import React from 'react';
import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Area } from '@visx/shape';
import { curveBasis } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { type IEventMilestone, type IEventAttendee } from '../../reducers/eventsSlice';

interface Props {
  eventMilestones: IEventMilestone[];
  eventAttendees: IEventAttendee[] | null;
}

export default function EventProgressChart({ eventMilestones, eventAttendees }: Props) {
  if (eventAttendees == null) {
    return <div>Loading...</div>;
  }

  interface DataPoint {
    index: number;
    value: number;
    title: string;
  }

  const x = (d: DataPoint) => d.index;
  const y = (d: DataPoint) => Math.max(d.value, 0);

  function range(n: number) {
    return Array.from(Array(n).keys());
  }

  function interpolatePoints(current: DataPoint, next: DataPoint) {
    if (!next) return current;
    const xStep = 0.25;
    const yStep = Math.abs(y(next) - y(current)) * 0.03;
    const yMid1 = Math.abs(y(current) - yStep);
    const yMid2 = Math.abs(y(next) + yStep);
    const xMid1 = Math.abs(x(current) + xStep);
    const xMid2 = Math.abs(x(next) - xStep);
    return [
      current,
      { index: xMid1, value: yMid1, title: current.title },
      { index: xMid2, value: yMid2, title: current.title },
    ];
  }

  function interpolateData(data: DataPoint[]) {
    return data.map((d, i) => interpolatePoints(d, data[i + 1])).flat();
  }

  // example for what segments should look like where index is the eventMilestone sequence_order
  // and the value is the number of attendees who have reached that milestone
  // segments = [
  //   { index: 0, title: 'Sign-up', value: 3 },
  //   { index: 1, title: 'Sign-in', value: 3 },
  //   { index: 2, title: 'Deploy Streamlit', value: 3 },
  // ...
  // ];

  function getFunnelChartSegmentsData(eventMilestones: IEventMilestone[], eventAttendees: IEventAttendee[]) {
    const segments: DataPoint[] = [];

    // Add sign-up segment based on number of attendees
    segments.push({
      index: 0,
      value: eventAttendees.length,
      title: 'Sign-up',
    });

    // Add other milestone segments
    eventMilestones.forEach((milestone) => {
      const attendeeMilestones = eventAttendees.filter((attendee) =>
        attendee.milestones.some((m) => m.event_milestone_id === milestone.id),
      );
      segments.push({
        index: milestone.sequence_order,
        value: attendeeMilestones.length,
        title: milestone.title,
      });
    });

    // Add and end segment that doesn't get displayed but is used to calculate the last milestone
    segments.push({
      index: eventMilestones.length + 1,
      value: 0,
      title: 'end',
    });
    return segments;
  }

  const segments = getFunnelChartSegmentsData(eventMilestones, eventAttendees);

  const data: DataPoint[] = interpolateData(segments);

  function FunnelChart({ width, height }: { width: number; height: number }) {
    const numSegments = Math.max(...segments.map(x));
    const maxValue = Math.max(...data.map(y));

    const valuePadding = 30;
    const minmax = maxValue + valuePadding;
    const padding = width / numSegments / 2;

    const xScale = scaleLinear({
      range: [0, width],
      domain: [0, numSegments],
    });
    const yScale = scaleLinear({
      range: [height, 0],
      domain: [-minmax, minmax],
    });

    const areas = [
      { pad: 0, opacity: 1 },
      { pad: 15, opacity: 0.2 },
      { pad: 30, opacity: 0.1 },
    ];

    return (
      <svg width={width} height={height}>
        <LinearGradient id="gradient" from="#29B5E8" to="#0078A4" vertical={false} />
        <rect width={width} height={height} fill="#fff" rx={5} />
        {areas.map((area, i) => {
          return (
            <Area
              key={`area-${i}`}
              data={data}
              curve={curveBasis}
              x={(d) => xScale(x(d))}
              y0={(d) => yScale(y(d) + area.pad)}
              y1={(d) => yScale(-y(d) - area.pad)}
              fill="url(#gradient)"
              fillOpacity={area.opacity}
              stroke="transparent"
            />
          );
        })}
        {data.map((d, i) => {
          if (!data[i + 1] || i === data.length - 1) return null;
          const r = range(numSegments);
          return (
            <React.Fragment key={`label-${i}`}>
              {r.includes(x(d)) && (
                <foreignObject
                  width={xScale(r.length) / r.length - padding / 2}
                  height={height}
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  x={xScale(x(d)) + padding / 4}
                  y={height / 20}
                >
                  <span style={{ color: '#24323D', fontSize: '10pt' }}>{`${d.title}`}</span>
                </foreignObject>
              )}
              {r.includes(x(d)) && (
                <text
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  x={xScale(x(d)) + padding}
                  y={height / 2}
                  dy={'.33em'}
                  fill="white"
                  fontSize={40}
                  fontWeight={'bold'}
                  textAnchor="middle"
                >
                  {`${y(d)}`}
                </text>
              )}
              {r.includes(x(d) + 1) && (
                <line
                  x1={xScale(x(d) + 1)}
                  x2={xScale(x(d) + 1)}
                  y1={0}
                  y2={height}
                  stroke="white"
                  strokeWidth={4}
                  strokeOpacity={0.7}
                />
              )}
            </React.Fragment>
          );
        })}
      </svg>
    );
  }

  return (
    <div className="funnel-chart">
      <ParentSize>
        {(parent) => {
          return <FunnelChart width={parent.width} height={parent.height} />;
        }}
      </ParentSize>
    </div>
  );
}
