import { configureStore, type ThunkAction, type Action } from '@reduxjs/toolkit';
import projectsReducer from './reducers/projectsSlice';
import accountReducer from './reducers/accountSlice';
import deploymentsReducer from './reducers/deploymentsSlice';
import solutionsReducer from './reducers/solutionsSlice';
import eventsReducer from './reducers/eventsSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const persistProjectsConfig = {
  key: 'projects',
  storage,
};

const persistAccountConfig = {
  key: 'account',
  storage,
};

const persistDeploymentsConfig = {
  key: 'deployments',
  storage,
};

const persisSolutionsConfig = {
  key: 'solutions',
  storage,
};

const persistEventsConfig = {
  key: 'events',
  storage,
};

const persistedProjectsReducer = persistReducer(persistProjectsConfig, projectsReducer);
const persistedAccountReducer = persistReducer(persistAccountConfig, accountReducer);
const persistedDeploymentsReducer = persistReducer(persistDeploymentsConfig, deploymentsReducer);
const persistedSolutionsReducer = persistReducer(persisSolutionsConfig, solutionsReducer);
const persistedEventsReducer = persistReducer(persistEventsConfig, eventsReducer);

export const store = configureStore({
  reducer: {
    projects: persistedProjectsReducer,
    account: persistedAccountReducer,
    deployments: persistedDeploymentsReducer,
    solutions: persistedSolutionsReducer,
    events: persistedEventsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
