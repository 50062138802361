import React from 'react';
import { Link } from 'react-router-dom';
import { type IEvent } from '../../reducers/eventsSlice';
import EventItem from '../project/EventItem';

const EventSection = ({ events, title }: { events: IEvent[]; title: string }) => (
  <li className="space-y-2 rounded-md px-2 py-4 bg-dataops-supporting-gray/40 list-none">
    <div className="flex items-center justify-between">
      <h2 className="flex-grow px-2 text-md text-dataops-secondary-dark-blue">{title}</h2>
      <div className="flex justify-center items-center w-6 h-6 mr-2 rounded-full bg-dataops-primary-dark-blue/10 text-xs">
        {events.length}
      </div>
    </div>
    <div className="relative grid grid-cols-11 gap-x-4 px-2 py-2">
      <div className="col-span-3 flex items-center justify-left text-sm">Event</div>
      <div className="col-span-2 flex items-center justify-left text-sm">Approval Status</div>
      <div className="col-span-2 flex items-center justify-center text-sm">Build Date</div>
      <div className="col-span-2 flex items-center justify-center text-sm">Decommission Date </div>
      <div className="col-span-2 flex items-center justify-left text-sm">Accounts allocated</div>
    </div>
    <ul role="list" className="w-full space-y-1">
      {events.map((event) => (
        <li key={event.slug}>
          <Link to={`/event-deployments/${event.slug}`}>
            <EventItem event={event} />
          </Link>
        </li>
      ))}
    </ul>
  </li>
);

export default EventSection;
