// Custom hook for managing tabs in the navigation sidebar
import { useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { addTab } from '../reducers/accountSlice';
import { type IVerticalNavLinkTab } from '../components/nav/VerticalNav';

/**
 * Hook for adding tabs in the sidebar navigation without auto-removing them
 * This ensures tabs persist across navigation within the application
 * @param tab The tab configuration object
 * @param dependencies Array of dependencies that should trigger tab recreation when changed
 */
export function useNavigationTab(tab: IVerticalNavLinkTab | null, dependencies: any[] = []) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Only add the tab if it has valid data
    if (tab?.label?.trim()) {
      dispatch(
        addTab({
          ...tab,
          status: 'loaded',
        }),
      );
    }
    // No cleanup function to ensure tabs persist across navigation
  }, [...dependencies]);
}
