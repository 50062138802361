import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getConfig } from '../../config/config-helper';
import { useAppDispatch } from '../../hooks';
import { fetchSolution, type ISolution } from '../../reducers/solutionsSlice';
import ProjectIcon from '../common/ProjectIcon';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import CopyUrlButton from '../controls/CopyUrlButton';
import { useSolution } from '../../hooks/useSolution';
import { useDeployments } from '../../hooks/useDeployments';
import { fetchDeployments, type IDeployment } from '../../reducers/deploymentsSlice';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { classNames } from '../../utilities/styleUtils';
import PublishUnPublishButton from '../controls/PublishUnPublishButton';
import { useToken } from '../../hooks/useToken';
import NewDeploymentButton from '../controls/NewDeploymentButton';
import DeploymentListItem from '../project/DeploymentListItem';
import SectionLayout from '../layout/SectionLayout';
import Grid3Layout from '../layout/Grid3Layout';
import Tag from '../common/Tag';
import MarkdownContent from '../common/MarkdownContent';
import { StarSolutionButton } from '../controls/StarSolutionButton';
import { DeploymentTotalTag } from '../project/DeploymentTotalTag';
import { HyperlinkOpenButton } from '../controls/HyperlinkOpenButton';
import { UserIcon } from '@heroicons/react/16/solid';
import TierTag from '../project/TierTag';
import DataOpsInfinity from '../../assets/images/icons/DataOps_darkblue_icon.svg';
import { useNavigationTab } from '../../hooks/useNavigationTab';

function whichReleaseStatus(solution: ISolution | undefined): 'Published' | 'Draft' {
  if (solution?.fullPath.includes('/solution-drafts')) {
    return 'Draft';
  }
  return 'Published';
}

export default function SolutionPage() {
  const { dataopsliveBaseUrl } = getConfig();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const token = useToken();
  const { username, canPublish } = useCurrentUser();
  const { solution } = useSolution(id);
  const { deployments } = useDeployments();
  const fullPath = `/solutions/${id ?? ''}`;
  const solutionDolUrl = `${dataopsliveBaseUrl}/${solution?.fullPath ?? ''}`;
  const avatarUrl = solution?.avatarUrl ?? solution?.listing?.icon;
  const [releaseStatus, setReleaseStatus] = useState<'Published' | 'Draft'>(whichReleaseStatus(solution));
  const [triggerPublish, setTriggerPublish] = useState<boolean>(false);
  const [visibleDeployments, setVisibleDeployments] = useState(5);
  const [filteredDeployments, setFilteredDeployments] = useState<IDeployment[]>([]);
  // Run once to get the solution details
  useEffect(() => {
    dispatch(fetchSolution({ token, fullPath, iid: id })).catch((err) => {
      console.log('Fetch solution error:', err);
    });
  }, []);

  useEffect(() => {
    if (searchParams.has('publish')) {
      if (releaseStatus === 'Draft' && canPublish) {
        setTriggerPublish(true);
      }
      searchParams.delete('publish');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useNavigationTab(
    solution?.listing?.name
      ? {
          section: 'discover/solutions',
          label: `${solution?.listing?.name}`,
          href: fullPath,
          status: solution?.listing?.name ? 'loaded' : 'loading',
        }
      : null,
    [solution?.listing?.name, token, releaseStatus],
  );

  useEffect(() => {
    getDeployments();
  }, [solution?.listing?.name, token, solution?.id]);

  useEffect(() => {
    setFilteredDeployments(
      deployments.filter((deployment) => {
        const variable = deployment.ciVariables.find(
          (variable) => variable.key === 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID',
        );
        if (variable) {
          return variable.value === id;
        } else {
          return false;
        }
      }),
    );
  }, [id]);

  useEffect(() => {
    if (solution?.fullPath.includes('/solution-drafts')) {
      setReleaseStatus('Draft');
    } else {
      setReleaseStatus('Published');
    }
  }, [solution?.fullPath]);

  function getDeployments() {
    dispatch(fetchDeployments({ token, username })).catch((err) => {
      console.log('Fetch deployments error:', err);
    });
  }

  function handleSeeMore() {
    setVisibleDeployments((prevVisible) => prevVisible + 5);
  }

  if (solution?.listing?.name === undefined || solution?.listing?.name === '') {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return <SolutionPageSkeleton />;
  }

  return (
    <>
      <main>
        <header className="relative isolate pt-10">
          <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
            <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
              <div
                style={{
                  clipPath:
                    'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                }}
                className={classNames(
                  'aspect-[1154/678] w-[72.125rem] bg-gradient-to-br',
                  releaseStatus === 'Published'
                    ? 'from-dataops-primary-light-blue to-dataops-secondary-dark-blue'
                    : 'from-dataops-secondary-orange to-dataops-secondary-red',
                )}
              />
            </div>
            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
          </div>

          <PageCenteredLayout>
            <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex items-center gap-x-6">
                <ProjectIcon iconSrc={avatarUrl} />
                <h1>
                  <div className="mt-1 text-base font-semibold text-dataops-primary-dark-blue">
                    {releaseStatus === 'Draft' ? '[Draft] ' : ''}
                    {solution?.listing?.name}
                  </div>
                  <a
                    href={solutionDolUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-1 text-sm/6 text-gray-500 hover:underline"
                  >
                    <img src={DataOpsInfinity} className="w-4 h-4 mt-0.5" />
                    <span className="truncate text-ellipsis w-3/4">{solution.fullPath}</span>
                  </a>
                </h1>
              </div>
              <div className="flex flex-col items-end gap-y-2 sm:gap-x-1">
                {/* Controls */}
                <div className="flex gap-x-1">
                  <StarSolutionButton solution={solution} />
                  <CopyUrlButton view="solution" />
                </div>
                <div className="flex gap-x-1">
                  <TierTag tier={solution?.listing?.tier} />
                  <Tag color={'lightGray'}>
                    <UserIcon className="w-3 h-3 mr-1" />
                    <span>{solution?.listing?.creator}</span>
                  </Tag>
                  <DeploymentTotalTag solution={solution} />
                </div>
              </div>
            </div>
          </PageCenteredLayout>
        </header>

        <PageCenteredLayout>
          <Grid3Layout>
            <SectionLayout label="Actions" className="bg-dataops-supporting-gray/40 md:col-span-1 md:col-start-3">
              <div className="space-y-1">
                <Actions solution={solution} triggerPublish={triggerPublish} />
              </div>
            </SectionLayout>
            <SectionLayout label="Deployments" className="md:col-span-1 md:col-start-3 md:row-start-2">
              {filteredDeployments.length === 0 ? (
                <div className="text-sm/6 text-gray-500">No deployments yet</div>
              ) : (
                <div>
                  <ul role="list" className="w-full space-y-1">
                    {filteredDeployments.slice(0, visibleDeployments).map((deployment) => (
                      <DeploymentListItem key={deployment.id} deployment={deployment} mini={true} />
                    ))}
                  </ul>
                  {visibleDeployments < filteredDeployments.length && (
                    <button onClick={handleSeeMore} className="w-full pt-2 text-sm/6 text-sky-600 hover:underline">
                      See more deployments
                    </button>
                  )}
                </div>
              )}
            </SectionLayout>
            <SectionLayout label="Description" className="md:col-span-2 md:row-start-1">
              <p className="text-sm/6">{solution?.listing?.description}</p>
            </SectionLayout>
            <SectionLayout label="Tags" className="md:col-span-2 md:row-start-2">
              <div className="grid md:grid-cols-2 gap-x-2 gap-y-4 text-sm/6 text-gray-500">
                <TagsList label="Features" tags={solution?.listing?.snowflake_feature ?? []} />
                <TagsList label="Verticals" tags={solution?.listing?.vertical_applicability ?? []} />
                <TagsList label="Use Cases" tags={solution?.listing?.use_case ?? []} />
                <TagsList label="Workload" tags={solution?.listing?.workload ?? []} />
                <TagsList label="Partners" tags={solution?.listing?.partner ?? []} />
              </div>
            </SectionLayout>
            {solution?.listing?.preview && (
              <SectionLayout label="Learn more" className="md:col-span-3">
                <MarkdownContent markdown={solution?.listing?.preview} />
              </SectionLayout>
            )}
            {solution?.listing?.help && (
              <SectionLayout label="Help" className="md:col-span-3">
                <MarkdownContent markdown={solution?.listing?.help} />
              </SectionLayout>
            )}
          </Grid3Layout>
        </PageCenteredLayout>
      </main>
    </>
  );
}

function TagsList({ label, tags }: { label: string; tags: string[] }) {
  return tags.length !== 0 ? (
    <div className="">
      <h3>{label}</h3>
      <div className="space-x-1">
        {tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
}

function Actions({ solution, triggerPublish }: { solution: ISolution; triggerPublish: boolean }) {
  const { canPublish, canConsume } = useCurrentUser();

  if (canPublish) {
    return <PublisherActions solution={solution} triggerPublish={triggerPublish} />;
  }
  if (canConsume) {
    return <ConsumerActions solution={solution} />;
  }
  return <ReadOnlyActions />;
}

function PublisherActions({ solution, triggerPublish }: { solution: ISolution; triggerPublish: boolean }) {
  const { canPublish } = useCurrentUser();
  const releaseStatus = whichReleaseStatus(solution);
  return (
    <>
      <ConsumerActions solution={solution} />
      {canPublish && (
        <PublishUnPublishButton
          solution={solution}
          canPublish={canPublish}
          releaseStatus={releaseStatus}
          triggerPublish={triggerPublish}
          size="large"
        />
      )}
    </>
  );
}

function ConsumerActions({ solution }: { solution: ISolution }) {
  const tier = solution?.listing?.tier ?? 2;
  if (tier === 4) {
    return <HyperlinkOpenButton solution={solution} />;
  } else {
    return <NewDeploymentButton solution={solution} size="large" buttonText="New SSC Deployment" />;
  }
}

function ReadOnlyActions() {
  return <div className="text-sm/6 text-gray-500">No actions for a read-only user.</div>;
}

function SolutionPageSkeleton() {
  return (
    <main>
      <header className="relative isolate pt-10">
        <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
          <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
            <div
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
              className={classNames(
                'aspect-[1154/678] w-[72.125rem] bg-gradient-to-br',
                'from-dataops-supporting-gray to-dataops-supporting-black',
              )}
            />
          </div>
          <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
        </div>

        <PageCenteredLayout>
          <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div className="flex items-center gap-x-6">
              <ProjectIcon iconSrc={undefined} className="animate-pulse" />
              <h1>
                <div className="mt-1 w-60 h-6 rounded-lg text-base font-semibold bg-dataops-supporting-gray bg-shine"></div>
                <a
                  href={'solutionDolUrl'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-1 flex items-center space-x-1 text-sm/6 text-gray-500 hover:underline"
                >
                  <img src={DataOpsInfinity} className="w-4 h-4 mt-0.5 animate-pulse opacity-40" />
                  <div className="w-60 h-5 rounded-lg bg-dataops-supporting-gray bg-shine"></div>
                </a>
              </h1>
            </div>
            <div className="flex items-center sm:gap-x-1">
              {/* Controls */}
              <div className="w-20 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
              <div className="w-14 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
              <div className="w-20 h-6 rounded-md bg-dataops-supporting-gray bg-shine"></div>
            </div>
          </div>
        </PageCenteredLayout>
      </header>

      <PageCenteredLayout>
        <Grid3Layout>
          <SectionLayout
            skeleton
            label="Actions"
            className="bg-dataops-supporting-gray/40 md:col-span-1 md:col-start-3"
          >
            <div className="space-y-1">{/* <Actions /> */}</div>
          </SectionLayout>
          <SectionLayout skeleton label="Deployments" className="md:col-span-1 md:col-start-3 md:row-start-2">
            <></>
          </SectionLayout>
          <SectionLayout skeleton label="Description" className="md:col-span-2 md:row-start-1">
            <div className="space-y-2">
              <p className="w-full h-4 rounded-lg bg-shine"></p>
              <p className="w-full h-4 rounded-lg bg-shine"></p>
              <p className="w-1/2 h-4 rounded-lg bg-shine"></p>
            </div>
          </SectionLayout>
          <SectionLayout skeleton label="Tags" className="md:col-span-2 md:row-start-2">
            <div className="grid md:grid-cols-2 gap-x-2 gap-y-4 text-sm/6 text-gray-500">
              <div className="flex space-x-1">
                <div className="w-20 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-14 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-10 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-14 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
              </div>
              <div className="flex space-x-1">
                <div className="w-20 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-10 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-20 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-14 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
              </div>
              <div className="flex space-x-1">
                <div className="w-20 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-20 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
                <div className="w-14 h-5 rounded-sm bg-dataops-supporting-gray bg-shine"></div>
              </div>
            </div>
          </SectionLayout>
        </Grid3Layout>
      </PageCenteredLayout>
    </main>
  );
}
