import { UserIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';
import { type IEventMilestone, type IEventAttendee, type IMilestoneProgress } from '../../reducers/eventsSlice';
import EventAttendeeProgressIcon from './EventAttendeeProgressIcon';

interface Props {
  attendee: IEventAttendee;
  eventMilestones: IEventMilestone[];
  nextMilestoneId: number | null;
}

export default function EventAttendeeProgressRow({ attendee, eventMilestones, nextMilestoneId }: Props) {
  const componentRef = useRef(null);

  function findLatestCompletedMilestone(attendeeMilestones: IMilestoneProgress[]): string {
    // if length of milestones is not 0 return last milestone
    if (attendeeMilestones.length === 0) {
      return '';
    } else {
      // return the name of the milestone which the attendee has achieved with the latest achieved_by timestamp
      const latestMilestone = attendeeMilestones.reduce((milestone1, milestone2) => {
        if (!milestone1 || new Date(milestone2.achieved_by) > new Date(milestone1.achieved_by)) {
          return milestone2;
        }
        return milestone1;
      }, attendeeMilestones[0]);

      return eventMilestones.find((milestone) => milestone.id === latestMilestone.event_milestone_id)?.title ?? '';
    }
  }

  return (
    <tr ref={componentRef} className="hover:bg-gray-50">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
        <div className="flex items-center gap-x-4">
          <UserIcon className="w-3 h-3" />
          <div>
            <p className="font-semibold text-gray-900">{attendee.name}</p>
            <p className="text-gray-500 text-xs">{attendee.email}</p>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
        <div className="flex items-center space-x-1">
          <div>
            <a href={attendee.account_url} className="text-dataops-secondary-dark-blue hover:underline">
              {attendee.account_identifier}
            </a>
            <p className="text-gray-500 text-xs">At {attendee.allocated_at}</p>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
        <div className="flex items-center space-x-2">
          {eventMilestones.map((milestone, index) => {
            return (
              <EventAttendeeProgressIcon
                key={index}
                attendee={attendee}
                milestone={milestone}
                isNextMilestone={milestone.id === nextMilestoneId}
              />
            );
          })}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
        <div className="flex items-center space-x-1">
          {/* latest successfully achieved milestone name */}
          <p className="pl-3 font-semibold text-gray-700">{findLatestCompletedMilestone(attendee.milestones)}</p>
        </div>
      </td>
    </tr>
  );
}
